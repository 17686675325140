import { Button, Drawer, TablePaginationConfig } from "antd";
import React from "react";
import ActionsHistoryFilters from "../../../components/actions-history-filters";
import {
  api,
  defaultAPIErrorHandler,
  IActionHistory,
  IFindManyActionsHistoryDTO,
  IPaginatedList,
} from "../../../services/api";
import ActionsHistoryTable from "./table";
import { Dayjs } from "dayjs";

const ActionsHistory = () => {
  const [filters, setFilters] = React.useState<IFindManyActionsHistoryDTO>({
    page: 0,
  });
  const [items, setItems] = React.useState<IActionHistory[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);
  const [showFiltersDrawer, setShowFiltersDrawer] = React.useState(false);

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  const onChangeFilters = React.useCallback(
    (
      changedFilters: IFindManyActionsHistoryDTO & {
        startDate?: Dayjs;
        endDate?: Dayjs;
      }
    ) => {
      const startDate = changedFilters.startDate
        ? changedFilters.startDate.toISOString().split("T")[0]
        : undefined;
      const endDate = changedFilters.endDate
        ? changedFilters.endDate.toISOString().split("T")[0]
        : undefined;

      const { action, changes, entity, entityId, userId, userName } =
        changedFilters;

      setFilters({
        ...filters,
        action: action || undefined,
        changes: changes || undefined,
        entity: entity || undefined,
        entityId: entityId || undefined,
        userId: userId || undefined,
        userName: userName || undefined,
        startDate: startDate || undefined,
        endDate: endDate || undefined,
      });

      setShowFiltersDrawer(false);
    },
    [filters]
  );

  React.useEffect(() => {
    setLoading(true);
    setItems([]);
    api
      .get<IPaginatedList<IActionHistory>>("/actions-history", {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      <Drawer
        title="Filtrar histórico de ações"
        placement={"bottom"}
        closable={true}
        onClose={() => setShowFiltersDrawer(false)}
        open={showFiltersDrawer}
        key={"ah-filters"}
      >
        <ActionsHistoryFilters onSubmit={onChangeFilters} />
      </Drawer>
      <Button
        onClick={() => setShowFiltersDrawer(true)}
        style={{ alignSelf: "flex-end", marginBottom: 20 }}
      >
        Filtrar resultados
      </Button>
      <ActionsHistoryTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default ActionsHistory;
