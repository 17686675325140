import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Button, Table, TablePaginationConfig } from "antd";
import {
  IApiContractor,
  IWhitelistFile,
  api,
  defaultAPIErrorHandler,
  getContractors,
} from "../../../../services/api";
import "./index.css";

const status = {
  DONE: "Concluído",
  PROCESSING: "Processando",
  ERROR: "Erro",
};

const WhitelistFilesTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IWhitelistFile[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const [contractors, setContractors] = React.useState<IApiContractor[]>([]);

  React.useEffect(() => {
    getContractors().then(setContractors);
  }, []);

  const getContractorById = React.useCallback(
    (id: string) => {
      return contractors.find((contractor) => contractor.id === id);
    },
    [contractors]
  );

  const handleDownload = React.useCallback(async (file: IWhitelistFile) => {
    api
      .get(`/whitelist/files/download/${file.id}`)
      .then((response) => {
        window.open(response.data?.signedUrl, "_blank");
      })
      .catch(defaultAPIErrorHandler);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Data de envio",
        render: (_value: unknown, record: IWhitelistFile, _index: number) => (
          <span>
            {record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY")
              : ""}
          </span>
        ),
        dataIndex: "createdAt",
        key: "createdAt",
      },
      {
        title: "Contratante",
        render: (_value: unknown, record: IWhitelistFile, _index: number) => (
          <span>{getContractorById(record.contractorId)?.tradeName || ""}</span>
        ),
        dataIndex: "contractorId",
      },
      {
        title: "Nome do usuário",
        dataIndex: ["user", "name"],
        key: "user.name",
      },
      {
        title: "Status",
        dataIndex: "status",
        render: (_value: unknown, record: IWhitelistFile, _index: number) => (
          <span>{status[record.status]}</span>
        ),
        key: "status",
      },
      {
        title: " Mensagem de status",
        dataIndex: "statusMessage",
        key: "statusMessage",
      },
      {
        title: "Ações",
        render: (_value: unknown, record: IWhitelistFile, _index: number) => (
          <Button onClick={() => handleDownload(record)}>Baixar arquivo</Button>
        ),
        key: "edit",
      },
    ],
    [handleDownload, getContractorById]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ showSizeChanger: false, ...paginationConfig }}
      columns={columns}
      className="table-whitelist-files"
      loading={loading}
      rowKey="id"
    />
  );
};

export default WhitelistFilesTable;
