import {
  Button,
  TablePaginationConfig,
  Form,
  Input,
  notification,
  Tabs,
} from "antd";
import React from "react";
import {
  api,
  defaultAPIErrorHandler,
  IApiAccess,
  IFindManyApiAccessDTO,
  IPaginatedList,
} from "../../../services/api";
import ApiAccessTable from "./table";
import { useNavigate } from "react-router-dom";
import { LoadingOutlined } from "@ant-design/icons";

const ApiAccess = () => {
  const [filters, setFilters] = React.useState<IFindManyApiAccessDTO>({
    page: 0,
  });
  const [items, setItems] = React.useState<IApiAccess[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);
  const [showCreation, setShowCreation] = React.useState(false);
  const navigate = useNavigate();

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  const handleCreateApiAccess = React.useCallback(
    async (data: { partnerName: string }) => {
      try {
        setLoading(true);
        await api.post("/api-access", {
          partnerName: data.partnerName,
        });

        notification.success({
          message: "Acesso criado com sucesso!",
        });

        setTimeout(() => navigate(0), 500);
      } catch (err) {
        defaultAPIErrorHandler(err);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  React.useEffect(() => {
    setLoading(true);
    setItems([]);
    api
      .get<IPaginatedList<IApiAccess>>("/api-access", {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  const tabs = React.useMemo(
    () => [
      {
        key: "1",
        label: "Elegibilidade",
        children: (
          <ApiAccessTable
            paginationConfig={paginationConfig}
            data={items}
            loading={loading}
          />
        ),
      },
    ],
    [items, paginationConfig, loading]
  );

  return (
    <div style={{ display: "flex", flexFlow: "column" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ marginRight: 10 }}>Acessos de API</h1>
        <Button onClick={() => setShowCreation(!showCreation)}>
          Criar novo acesso
        </Button>
      </div>
      <div>
        <p>
          O acesso deve ser criado individualmente para cada parceiro que deseja
          acessar a API.
        </p>
        <p>
          Um acesso revogado não pode ser restaurado, deve ser criado um novo
          acesso com uma chave diferente.
        </p>
      </div>
      {showCreation && (
        <div
          style={{
            width: "100%",
            background: "white",
            borderRadius: 5,
            display: "flex",
            flexFlow: "column",
            margin: "20px 0",
            padding: 20,
          }}
        >
          <b>Criar novo acesso</b>
          <br />
          <Form
            name="form-api-access-create"
            wrapperCol={{ span: 14 }}
            layout="vertical"
            onFinish={handleCreateApiAccess}
            style={{ maxWidth: 600, minWidth: 100 }}
          >
            <Form.Item
              label="Nome do parceiro"
              name="partnerName"
              rules={[
                {
                  required: true,
                  message:
                    "O nome do parceiro deve ter pelo menos 2 caracteres.",
                  min: 2,
                },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit">
                {loading ? <LoadingOutlined /> : "Criar"}
              </Button>
            </Form.Item>
          </Form>
        </div>
      )}
      <Tabs defaultActiveKey="1" items={tabs} />
    </div>
  );
};

export default ApiAccess;
