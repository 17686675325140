import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Button, Table, TablePaginationConfig } from "antd";
import {
  CampaignStatus,
  CampaignType,
  ICampaign,
} from "../../../../services/api";
import "./index.css";
import { useNavigate } from "react-router-dom";

const readableCampaignType = {
  [CampaignType.PUSH]: "Push",
};

const readableCampaignStatus = {
  [CampaignStatus.WAITING]: "Aguardando",
  [CampaignStatus.RUNNING]: "Em execução",
  [CampaignStatus.DONE]: "Finalizado",
  [CampaignStatus.ERROR]: "Erro",
};

const CampaignsTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: ICampaign[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        render: (_value: unknown, record: ICampaign, _index: number) => (
          <span>{readableCampaignType[record.type]}</span>
        ),
      },
      {
        title: "Título",
        dataIndex: "title",
        key: "title",
      },
      {
        title: "Mensagem",
        dataIndex: "content",
        key: "content",
      },
      {
        title: "Data de disparo",
        dataIndex: "executionDate",
        key: "executionDate",
        render: (_value: unknown, record: ICampaign, _index: number) => (
          <span>
            {record.executionDate
              ? dayjs(record.executionDate).format("DD/MM/YYYY HH:mm")
              : "Imediato"}
          </span>
        ),
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_value: unknown, record: ICampaign, _index: number) => (
          <span>{readableCampaignStatus[record.status]}</span>
        ),
      },
      {
        title: "Recebedores estimados",
        dataIndex: "estimatedRecipients",
        key: "estimatedRecipients",
      },
      {
        title: "Data de criação",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_value: unknown, record: ICampaign, _index: number) => (
          <span>{dayjs(record.createdAt).format("DD/MM/YYYY HH:mm")}</span>
        ),
      },
      {
        title: "Ações",
        render: (_value: unknown, record: ICampaign, _index: number) => (
          <Button
            onClick={() => navigate(`/marketing/campaigns/${record.id}/edit`)}
          >
            Editar
          </Button>
        ),
        key: "edit",
      },
    ],
    [navigate]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ showSizeChanger: false, ...paginationConfig }}
      columns={columns}
      className="table-campaigns"
      loading={loading}
      rowKey="id"
    />
  );
};

export default CampaignsTable;
