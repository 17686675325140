import React from "react";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import "dayjs/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import {
  Form,
  DatePicker,
  Button,
  Input,
  Upload,
  UploadFile,
  notification,
  Select,
} from "antd";
import { UploadChangeParam } from "antd/es/upload";
import BackButton from "../../../../components/back-button";
import { api, defaultAPIErrorHandler, marketingApi } from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import {
  BannerType,
  readableBannerTypes,
} from "../../../../services/structures";

const CreateBanner = () => {
  const [file, setFile] = React.useState<UploadFile | null>(null);
  const [loading, setLoading] = React.useState(false);

  const navigate = useNavigate();

  const handleUpload = React.useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.fileList.length === 0) {
        setFile(null);
        return;
      }

      const allowedTypes = ["image/png"];

      if (!allowedTypes.includes(info.file?.type || "")) {
        notification.error({
          message: "A imagem deve ser do tipo " + allowedTypes.join(", "),
        });
        setFile(null);
        return;
      }

      setFile(info.file);
    },
    []
  );

  const handleSubmit = React.useCallback(
    (data: {
      file_info: UploadChangeParam<UploadFile>;
      type: BannerType;
      destinationUrl?: string;
      startingDate: Date;
      finalDate?: Date;
    }) => {
      if (!file) {
        notification.error({
          message: "Por favor, selecione uma imagem!",
        });
        return;
      }

      setLoading(true);
      var bodyFormData = new FormData();

      bodyFormData.append("file", data.file_info.file.originFileObj as File);
      bodyFormData.append(
        "startingDate",
        data.startingDate.toISOString().split("T")[0]
      );

      if (data.finalDate) {
        bodyFormData.append(
          "finalDate",
          data.finalDate.toISOString().split("T")[0]
        );
      }

      if (data.destinationUrl) {
        bodyFormData.append("destinationUrl", data.destinationUrl);
      }

      if (data.type) {
        bodyFormData.append("type", data.type);
      }

      marketingApi
        .post("/banners", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          notification.success({
            message: "Banner criado com sucesso!",
          });

          return navigate("/marketing/banners");
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, file]
  );

  return (
    <div>
      <BackButton
        title="Marketing > Banners > Criar"
        pathToNavigate="/marketing/banners"
        showConfirmation
      />
      <h1>Criar novo Banner</h1>

      <Form
        name="form-banner-create"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmit}
        style={{ maxWidth: 600, minWidth: 100 }}
        initialValues={{
          type: BannerType.HEADER_HOME,
        }}
      >
        <Form.Item
          label="Imagem"
          name="file_info"
          rules={[
            {
              required: true,
              message: "Por favor, selecione uma imagem!",
            },
          ]}
        >
          <Upload
            customRequest={({ onSuccess }) => {
              setTimeout(() => {
                onSuccess?.("ok");
              }, 0);
            }}
            fileList={file ? [file] : []}
            listType="picture"
            multiple={false}
            accept="image/png"
            onPreview={() => {}}
            onChange={handleUpload}
          >
            {file ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
          </Upload>
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12 }} label="Tipo" name="type">
          <Select>
            {Object.keys(BannerType).map((key: string) => (
              <Select.Option key={key} value={key}>
                {readableBannerTypes[key as BannerType]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="URL de destino" name="destinationUrl">
          <Input />
        </Form.Item>

        <Form.Item
          label="Data inicial"
          name="startingDate"
          rules={[
            {
              required: true,
              message: "Por favor, selecione uma data inicial!",
            },
          ]}
        >
          <DatePicker format={"DD/MM/YYYY"} locale={locale} />
        </Form.Item>

        <Form.Item label="Data final" name="finalDate">
          <DatePicker format={"DD/MM/YYYY"} locale={locale} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateBanner;
