import { TablePaginationConfig } from "antd";
import React from "react";
import {
  api,
  defaultAPIErrorHandler,
  IApiContractor,
  IPaginatedList,
} from "../../../services/api";
import ContractorsTable from "./table";

const Contractors = () => {
  const [filters, setFilters] = React.useState({
    page: 0,
  });
  const [items, setItems] = React.useState<IApiContractor[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  React.useEffect(() => {
    setLoading(true);
    setItems([]);
    api
      .get<IPaginatedList<IApiContractor>>("/contractors-api", {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ marginRight: 10 }}>Contratantes</h1>
      </div>
      {/* <ContractorsHeaderSearch onSearch={onSearch} /> */}

      {/* {errorItems && errorItems.length > 0 && (
        <ContractorsError data={errorItems} />
      )} */}

      <ContractorsTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default Contractors;
