import React from "react";
import "dayjs/locale/pt-br";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Button, Input } from "antd";
import BackButton from "../../../../components/back-button";
import {
  api,
  getContractors,
  IApiContractor,
  IWhitelistResponse,
} from "../../../../services/api";

const SearchWhitelistCpf = () => {
  const [loading, setLoading] = React.useState(false);
  const [contractors, setContractors] = React.useState<IApiContractor[]>([]);
  const [searchResult, setSearchResult] = React.useState<{
    found: boolean;
    cpf: string;
    result: IWhitelistResponse | null;
  } | null>(null);

  const handleSubmit = React.useCallback(({ cpf }: { cpf: string }) => {
    setLoading(true);

    api
      .get(`/whitelist/cpf/${cpf}`)
      .then((response) => {
        if (!response.data?.allowed) {
          setSearchResult({
            found: false,
            cpf,
            result: null,
          });
          return;
        }

        setSearchResult({
          found: true,
          cpf,
          result: response.data,
        });
      })
      .catch(() => {
        setSearchResult({
          found: false,
          cpf,
          result: null,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  React.useEffect(() => {
    getContractors().then(setContractors);
  }, []);

  return (
    <div>
      <BackButton
        title={`Administração > Whitelist > Pesquisar`}
        pathToNavigate="/admin/whitelist"
      />
      <h1>Pesquisar CPF na whitelist</h1>
      <br />
      <Form
        name="form-whitelist-search"
        layout="inline"
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        style={{ maxWidth: 600, minWidth: 100 }}
      >
        <Form.Item
          label="CPF"
          name="cpf"
          rules={[
            {
              required: true,
              message: "Por favor, insira um cpf!",
            },
            {
              pattern: /^[0-9]*$/,
              message: "Por favor, insira somente números!",
            },
            {
              len: 11,
              message: "Por favor, insira um cpf válido!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item style={{ marginTop: 30 }}>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Pesquisar"}
          </Button>
        </Form.Item>
      </Form>

      {searchResult && !loading && (
        <div>
          <br />
          <h3>Resultado da pesquisa</h3>
          <br />
          <div>
            <b>CPF:</b> {searchResult.cpf}
            <br />
            <b>
              Status:{" "}
              {searchResult.found ? (
                <>
                  <span style={{ color: "green" }}>Encontrado</span>
                  <br />
                  <br />
                  <span>Contratantes:</span>
                  <br />
                  {searchResult.result?.sources?.map((source) => {
                    const contractor = contractors.find(
                      (contractor) => contractor.whitelistSource === source
                    );

                    if (contractor) {
                      return (
                        <span style={{ marginLeft: 20, fontWeight: "normal" }}>
                          {contractor.tradeName} ({source})
                          <br />
                        </span>
                      );
                    }

                    return source;
                  })}
                </>
              ) : (
                <span style={{ color: "orange" }}>Não encontrado</span>
              )}
            </b>
          </div>
        </div>
      )}
    </div>
  );
};

export default SearchWhitelistCpf;
