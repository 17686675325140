import { Button, TablePaginationConfig } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  defaultAPIErrorHandler,
  ICampaign,
  IPaginatedList,
  marketingApi,
} from "../../../services/api";
import CampaignsTable from "./table";

const Campaigns = () => {
  const [filters, setFilters] = React.useState({
    page: 0,
  });
  const [items, setItems] = React.useState<ICampaign[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  const findCampaigns = React.useCallback(() => {
    setLoading(true);
    marketingApi
      .get<IPaginatedList<ICampaign>>("campaigns", {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  React.useEffect(() => {
    findCampaigns();
  }, [findCampaigns]);

  React.useEffect(() => {
    const interval = setInterval(() => {
      findCampaigns();
    }, 60 * 1000);

    return () => {
      clearInterval(interval);
    };
  }, [findCampaigns]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ marginRight: 10 }}>Campanhas</h1>
        <Button onClick={() => navigate("/marketing/campaigns/create")}>
          Criar nova campanha
        </Button>
      </div>

      <CampaignsTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default Campaigns;
