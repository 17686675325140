export enum BannerType {
  HEADER_HOME = "HEADER_HOME",
}

export const readableBannerTypes = {
  [BannerType.HEADER_HOME]: "Página inicial",
};

export interface IBanner {
  id: number;
  type: BannerType;
  extension: string;
  destinationUrl?: string;
  startingDate: string;
  finalDate?: string;
  imageUrl?: string;
  createdAt: string;
  updatedAt: string;
}
