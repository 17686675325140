import { Result } from 'antd';

const CPFNotFound = () => {
  return (
    <Result
      status="error"
      title="CPF Não Encontrado"
      subTitle="CPF não foi encontrado em nossas bases."
    ></Result>
  );
};

export default CPFNotFound;
