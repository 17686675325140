import React from "react";
import "dayjs/locale/pt-br";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Button, Input, notification, Select } from "antd";
import BackButton from "../../../../components/back-button";
import {
  api,
  defaultAPIErrorHandler,
  getContractors,
  IApiContractor,
  ICreateWhitelistRegister,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";

const CreateWhitelistManually = () => {
  const [loading, setLoading] = React.useState(false);
  const [contractors, setContractors] = React.useState<IApiContractor[]>([]);
  const [selectedContractorId, setSelectedContractorId] = React.useState<
    string | null
  >(null);

  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    ({
      cpf,
      nomevida,
      status,
      bairro,
      cidade,
      datanascimento,
      email,
      matricula,
      sexo,
      telefone,
      telefone2,
      uf,
    }: ICreateWhitelistRegister) => {
      setLoading(true);

      const body = {
        contractorId: selectedContractorId,
        register: {
          cpf: cpf.trim(),
          nomevida: nomevida?.trim() || undefined,
          status,
          bairro: bairro?.trim() || undefined,
          cidade: cidade?.trim() || undefined,
          datanascimento: datanascimento?.trim() || undefined,
          email: email?.trim() || undefined,
          matricula: matricula?.trim() || undefined,
          sexo: sexo?.trim() || undefined,
          telefone: telefone?.trim() || undefined,
          telefone2: telefone2?.trim() || undefined,
          uf: uf?.trim() || undefined,
        },
      };

      api
        .post(`/whitelist`, body)
        .then(() => {
          notification.success({
            message: "Registro enviado com sucesso!",
          });

          return navigate("/admin/whitelist");
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, selectedContractorId]
  );

  React.useEffect(() => {
    getContractors().then(setContractors);
  }, []);

  return (
    <div>
      <BackButton
        title={`Administração > Whitelist > Criar manualmente`}
        pathToNavigate="/admin/whitelist"
        showConfirmation
      />
      <h1>Inserir na whitelist manualmente</h1>
      <br />
      Selecione o contratante:
      <br />
      <Select
        onChange={(value) => setSelectedContractorId(value)}
        className="contractor-select"
      >
        {contractors.map((contractor) => (
          <Select.Option key={contractor.id} value={contractor.id}>
            {contractor.tradeName}
          </Select.Option>
        ))}
      </Select>
      <br />
      <br />
      <Form
        name="form-whitelist-manual-insert"
        initialValues={{
          status: true,
        }}
        layout="inline"
        wrapperCol={{ span: 24 }}
        onFinish={handleSubmit}
        style={{ maxWidth: 1000, minWidth: 100 }}
        disabled={!selectedContractorId}
      >
        <Form.Item
          label="CPF"
          name="cpf"
          rules={[
            {
              required: true,
              message: "Por favor, insira um cpf!",
            },
            {
              pattern: /^[0-9]*$/,
              message: "Por favor, insira somente números!",
            },
            {
              len: 11,
              message: "Por favor, insira um cpf válido!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Nome" name="nomevida">
          <Input />
        </Form.Item>

        <Form.Item label="E-mail" name="email">
          <Input />
        </Form.Item>

        <Form.Item label="Matrícula" name="matricula">
          <Input />
        </Form.Item>

        <Form.Item label="Sexo" name="sexo">
          <Select>
            <Select.Option value="M">Masculino</Select.Option>
            <Select.Option value="F">Feminino</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Bairro" name="bairro">
          <Input />
        </Form.Item>

        <Form.Item label="Cidade" name="cidade">
          <Input />
        </Form.Item>

        <Form.Item
          label="Estado"
          name="uf"
          rules={[
            {
              len: 2,
              message: "Por favor, insira uma sigla de estado válida!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item label="Telefone" name="telefone">
          <Input />
        </Form.Item>

        <Form.Item label="Telefone 2" name="telefone2">
          <Input />
        </Form.Item>

        <Form.Item
          label="Data de nascimento"
          name="datanascimento"
          rules={[
            {
              pattern:
                /^([0-2][0-9]|(3)[0-1])(\/)(((0)[0-9])|((1)[0-2]))(\/)\d{4}$/,
              message:
                "Por favor, insira uma data válida no formato dd/mm/aaaa",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Usuário ativo"
          name="status"
          rules={[
            {
              required: true,
              message: "Por favor, selecione uma opção!",
            },
          ]}
        >
          <Select>
            <Select.Option value={true}>Sim</Select.Option>
            <Select.Option value={false}>Não</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item style={{ marginTop: 30 }}>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateWhitelistManually;
