import { Button, TablePaginationConfig, Tabs } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import {
  defaultAPIErrorHandler,
  IPaginatedList,
  marketingApi,
} from "../../../services/api";
import BannersTable from "./table";
import { IBanner } from "../../../services/structures";

const Banners = () => {
  const [filters, setFilters] = React.useState({
    onlyActive: true,
    page: 0,
  });
  const [items, setItems] = React.useState<IBanner[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  const onChangeTab = React.useCallback(
    (key: string) => {
      setFilters({
        ...filters,
        onlyActive: key === "1",
      });
    },
    [filters]
  );

  React.useEffect(() => {
    setLoading(true);
    setItems([]);
    marketingApi
      .get<IPaginatedList<IBanner>>("/banners", {
        params:{
          page: filters.page + 1,
        }
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  const tabs = React.useMemo(
    () => [
      {
        key: "1",
        label: "Ativos",
        children: (
          <BannersTable
            paginationConfig={paginationConfig}
            data={items}
            loading={loading}
          />
        ),
      },
      {
        key: "2",
        label: "Todos",
        children: (
          <BannersTable
            paginationConfig={paginationConfig}
            data={items}
            loading={loading}
          />
        ),
      },
    ],
    [items, paginationConfig, loading]
  );

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ marginRight: 10 }}>Banners</h1>
        <Button onClick={() => navigate("/marketing/banners/create")}>
          Criar novo banner
        </Button>
      </div>

      <Tabs defaultActiveKey="1" items={tabs} onChange={onChangeTab} />
    </div>
  );
};

export default Banners;
