import { Result } from 'antd';

const CPFFound = () => {
  return (
    <Result
      status="success"
      title="CPF Encontrado"
      subTitle="CPF foi encontrado em nossas bases."
    ></Result>
  );
};

export default CPFFound;
