import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Button, Table, TablePaginationConfig } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { IUser } from "../../../../hooks/global.structures";
import { roleReadableNames } from "../../../../components/sidemenu/roles";
import { UserRole } from "../../../../services/api";

const UsersTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IUser[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        title: "Nome",
        dataIndex: "name",
        key: "name",
      },
      {
        title: "E-mail",
        dataIndex: "email",
        key: "email",
      },
      {
        title: "Perfil",
        dataIndex: "role",
        key: "role",
        render: (_value: unknown, record: IUser, _index: number) => (
          <span>{roleReadableNames[record.role as UserRole]}</span>
        ),
      },
      {
        title: "Data de criação",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_value: unknown, record: IUser, _index: number) => (
          <span>
            {record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Data de alteração",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (_value: unknown, record: IUser, _index: number) => (
          <span>
            {record.updatedAt
              ? dayjs(record.updatedAt).format("DD/MM/YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Acesso permitido",
        dataIndex: "deletedAt",
        key: "deletedAt",
        render: (_value: unknown, record: IUser, _index: number) => (
          <span>{record.deletedAt ? "Não" : "Sim"}</span>
        ),
      },
      {
        title: "Ações",
        render: (_value: unknown, record: IUser, _index: number) => (
          <Button
            onClick={() =>
              navigate(`/admin/users/${record.id}/edit`, {
                state: record,
              })
            }
          >
            Editar
          </Button>
        ),
        key: "edit",
      },
    ],
    [navigate]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ showSizeChanger: false, ...paginationConfig }}
      columns={columns}
      className="table-users"
      loading={loading}
      rowKey="id"
    />
  );
};

export default UsersTable;
