import React from "react";
import "dayjs/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import dayjs from "dayjs";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, DatePicker, Button, Input, notification, Select } from "antd";
import BackButton from "../../../../components/back-button";
import { api, defaultAPIErrorHandler, marketingApi } from "../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import {
  BannerType,
  IBanner,
  readableBannerTypes,
} from "../../../../services/structures";

const EditBanner = () => {
  const [loading, setLoading] = React.useState(false);
  const { id } = useParams<{ id: string }>();
  const [banner, setBanner] = React.useState<IBanner | null>(null);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!id) {
      notification.error({
        message: "Banner não encontrado",
      });
      return navigate("/marketing/banners");
    }

    setLoading(true);
    marketingApi
      .get<IBanner>(`/banners/${id}`)
      .then((response) => {
        setBanner(response.data);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [id, navigate]);

  const handleSubmit = React.useCallback(
    (data: {
      type: BannerType;
      destinationUrl?: string;
      startingDate: Date;
      finalDate?: Date;
    }) => {
      setLoading(true);

      const { destinationUrl, startingDate, finalDate } = data;

      const body: {
        type?: BannerType;
        destinationUrl?: string;
        startingDate?: string;
        finalDate?: string | null;
      } = {};

      const formattedStartingDate = dayjs(startingDate.toISOString()).format(
        "YYYY-MM-DD"
      );
      const destinationUrlWasModified =
        destinationUrl !== banner?.destinationUrl;
      const startingDateWasModified =
        formattedStartingDate !== banner?.startingDate;
      const typeWasModified = data.type !== banner?.type;

      if (destinationUrlWasModified) {
        body["destinationUrl"] = destinationUrl;
      }

      if (startingDateWasModified) {
        body["startingDate"] = formattedStartingDate;
      }

      if (typeWasModified) {
        body["type"] = data.type;
      }

      if (finalDate) {
        const formattedFinalDate = dayjs(finalDate.toISOString()).format(
          "YYYY-MM-DD"
        );
        const finalDateWasModified = formattedFinalDate !== banner?.finalDate;

        if (finalDateWasModified) {
          body["finalDate"] = formattedFinalDate;
        }
      }

      const shouldRemoveFinalDate = !finalDate && banner?.finalDate;

      if (shouldRemoveFinalDate) {
        body["finalDate"] = null;
      }

      const shouldBeUpdated = !!Object.keys(body).length;

      if (!shouldBeUpdated) {
        notification.info({
          message: "Nenhuma alteração foi feita.",
        });

        setLoading(false);
        return navigate("/marketing/banners");
      }

      marketingApi
        .patch(`/banners/${id}`, body)
        .then(() => {
          notification.success({
            message: "Banner editado com sucesso!",
          });

          return navigate("/marketing/banners");
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, id, banner]
  );

  if (!banner)
    return (
      <>
        <BackButton
          title={`Marketing > Banners > ${id} > Editar`}
          pathToNavigate="/marketing/banners"
          showConfirmation
        />
        <h1>Editar Banner</h1>
        <center>
          <LoadingOutlined />
        </center>
      </>
    );

  return (
    <div>
      <BackButton
        title={`Marketing > Banners > ${id} > Editar`}
        pathToNavigate="/marketing/banners"
        showConfirmation
      />
      <h1>Editar Banner</h1>
      <img src={banner.imageUrl || ""} alt="banner" style={{ maxWidth: 400 }} />
      <br />
      <br />
      <br />
      <Form
        name="form-banner-edit"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={{
          type: BannerType.HEADER_HOME,
          destinationUrl: banner.destinationUrl,
          startingDate: dayjs(banner.startingDate),
          finalDate: banner.finalDate ? dayjs(banner.finalDate) : undefined,
        }}
        layout="horizontal"
        onFinish={handleSubmit}
        style={{ maxWidth: 600, minWidth: 100 }}
      >
        <Form.Item wrapperCol={{ span: 12 }} label="Tipo" name="type">
          <Select>
            {Object.keys(BannerType).map((key: string) => (
              <Select.Option key={key} value={key}>
                {readableBannerTypes[key as BannerType]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="URL de destino" name="destinationUrl">
          <Input />
        </Form.Item>

        <Form.Item
          label="Data inicial"
          name="startingDate"
          rules={[
            {
              required: true,
              message: "Por favor, selecione uma data inicial!",
            },
          ]}
        >
          <DatePicker format={"DD/MM/YYYY"} locale={locale} />
        </Form.Item>

        <Form.Item label="Data final" name="finalDate">
          <DatePicker format={"DD/MM/YYYY"} locale={locale} />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditBanner;
