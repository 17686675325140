import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Table, TablePaginationConfig } from "antd";
import "./index.css";
import { IActionHistory } from "../../../../services/api";

const ActionsHistoryTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IActionHistory[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const columns = React.useMemo(
    () => [
      {
        title: "ID do usuário",
        dataIndex: ["user", "id"],
        key: "user.id",
      },
      {
        title: "Nome do usuário",
        dataIndex: ["user", "name"],
        key: "user.name",
      },
      {
        title: "Ação",
        dataIndex: "action",
        key: "action",
      },
      {
        title: "Entidade afetada",
        dataIndex: "entity",
        key: "entity",
      },
      {
        title: "ID da entidade",
        dataIndex: "entityId",
        key: "entityId",
      },
      {
        title: "Modificações",
        dataIndex: "changes",
        key: "changes",
      },
      {
        title: "Data",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_value: unknown, record: IActionHistory, _index: number) => (
          <span>
            {record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
    ],
    []
  );

  return (
    <Table
      dataSource={data}
      pagination={{ showSizeChanger: false, ...paginationConfig }}
      columns={columns}
      className="table-actions"
      loading={loading}
      rowKey="id"
    />
  );
};

export default ActionsHistoryTable;
