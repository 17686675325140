import {
  PictureOutlined,
  RiseOutlined,
  SettingOutlined,
  UserOutlined,
  HistoryOutlined,
  BookOutlined,
  ShopOutlined,
  NotificationOutlined,
  TeamOutlined,
  CustomerServiceOutlined,
  KeyOutlined,
  BankOutlined,
  SolutionOutlined,
  CarryOutOutlined,
} from '@ant-design/icons';
import { ItemType } from 'antd/es/menu/hooks/useItems';
import { UserRole } from '../../services/api';
import { NavigateFunction } from 'react-router-dom';

export const menuKeysPaths: Record<string, string> = {
  '/marketing': '1',
  '/marketing/banners': '1-1',
  '/marketing/campaigns': '1-2',
  '/admin': '2',
  '/admin/users': '2-1',
  '/admin/actions-history': '2-2',
  '/admin/reports': '2-3',
  '/admin/companies': '2-4',
  '/admin/app-users': '2-5',
  '/admin/api-access': '2-6',
  '/admin/contractors': '2-7',
  '/admin/whitelist': '2-8',
  '/customer-care': '3',
  '/partner': '4',
  '/partner/validation': '4-1',
};

export const defaultRouteByRole: Record<UserRole, string> = {
  [UserRole.ADMIN]: '/admin/users',
  [UserRole.MARKETING]: '/marketing/banners',
  [UserRole.CUSTOMER_CARE]: '/admin/app-users',
  [UserRole.PARTNER]: '/partner/validation',
};

export const roleReadableNames: Record<UserRole, string> = {
  [UserRole.ADMIN]: 'Administrador',
  [UserRole.MARKETING]: 'Marketing',
  [UserRole.CUSTOMER_CARE]: 'Atendimento',
  [UserRole.PARTNER]: 'Parceiro',
};

const marketingMenus = (navigate: NavigateFunction): ItemType[] => {
  return [
    {
      key: menuKeysPaths['/marketing'],
      icon: <RiseOutlined />,
      label: 'Marketing',
      children: [
        {
          key: menuKeysPaths['/marketing/banners'],
          label: 'Banners',
          icon: <PictureOutlined />,
          onClick: () => navigate('/marketing/banners'),
        },
        {
          key: menuKeysPaths['/marketing/campaigns'],
          label: 'Campanhas',
          icon: <NotificationOutlined />,
          onClick: () => navigate('/marketing/campaigns'),
        },
      ],
    },
  ];
};

const customerCareMenus = (navigate: NavigateFunction): ItemType[] => {
  return [
    {
      key: menuKeysPaths['/customer-care'],
      icon: <CustomerServiceOutlined />,
      label: 'Atendimento',
      children: [
        {
          key: menuKeysPaths['/admin/actions-history'],
          label: 'Histórico',
          icon: <HistoryOutlined />,
          onClick: () => navigate('/admin/actions-history'),
        },
        {
          key: menuKeysPaths['/admin/reports'],
          label: 'Relatórios',
          icon: <BookOutlined />,
          onClick: () => navigate('/admin/reports'),
        },
        {
          key: menuKeysPaths['/admin/companies'],
          label: 'Parceiros',
          icon: <ShopOutlined />,
          onClick: () => navigate('/admin/companies'),
        },
        {
          key: menuKeysPaths['/admin/app-users'],
          label: 'Usuários do App',
          icon: <TeamOutlined />,
          onClick: () => navigate('/admin/app-users'),
          style: { lineHeight: 'normal', whiteSpace: 'normal' },
        },
        {
          key: menuKeysPaths['/admin/api-access'],
          label: 'Acessos de API',
          icon: <KeyOutlined />,
          onClick: () => navigate('/admin/api-access'),
        },
        {
          key: menuKeysPaths['/admin/whitelist'],
          label: 'Whitelist',
          icon: <SolutionOutlined />,
          onClick: () => navigate('/admin/whitelist'),
        },
      ],
    },
  ];
};

const adminMenus = (navigate: NavigateFunction): ItemType[] => {
  const marketingOptions = marketingMenus(navigate);

  return [
    ...marketingOptions,
    {
      key: menuKeysPaths['/admin'],
      icon: <SettingOutlined />,
      label: 'Administração',
      children: [
        {
          key: menuKeysPaths['/admin/users'],
          label: 'Usuários',
          icon: <UserOutlined />,
          onClick: () => navigate('/admin/users'),
        },
        {
          key: menuKeysPaths['/admin/actions-history'],
          label: 'Histórico',
          icon: <HistoryOutlined />,
          onClick: () => navigate('/admin/actions-history'),
        },
        {
          key: menuKeysPaths['/admin/reports'],
          label: 'Relatórios',
          icon: <BookOutlined />,
          onClick: () => navigate('/admin/reports'),
        },
        {
          key: menuKeysPaths['/admin/companies'],
          label: 'Parceiros',
          icon: <ShopOutlined />,
          onClick: () => navigate('/admin/companies'),
        },
        {
          key: menuKeysPaths['/admin/app-users'],
          label: 'Usuários do App',
          icon: <TeamOutlined />,
          onClick: () => navigate('/admin/app-users'),
          style: { lineHeight: 'normal', whiteSpace: 'normal' },
        },
        {
          key: menuKeysPaths['/admin/api-access'],
          label: 'Acessos de API',
          icon: <KeyOutlined />,
          onClick: () => navigate('/admin/api-access'),
        },
        {
          key: menuKeysPaths['/admin/contractors'],
          label: 'Contratantes',
          icon: <BankOutlined />,
          onClick: () => navigate('/admin/contractors'),
        },
        {
          key: menuKeysPaths['/admin/whitelist'],
          label: 'Whitelist',
          icon: <SolutionOutlined />,
          onClick: () => navigate('/admin/whitelist'),
        },
      ],
    },
  ];
};

const partnerMenus = (navigate: NavigateFunction): ItemType[] => {
  return [
    {
      key: menuKeysPaths['/partner'],
      icon: <RiseOutlined />,
      label: 'Parceiro',
      children: [
        {
          key: menuKeysPaths['/partner/validation'],
          label: 'Validação',
          icon: <CarryOutOutlined />,
          onClick: () => navigate('/partner/validation'),
        },
      ],
    },
  ];
};

export const getUserMenusByRole = (
  role: UserRole,
  navigate: NavigateFunction
): ItemType[] | undefined => {
  return (
    {
      [UserRole.ADMIN]: adminMenus(navigate),
      [UserRole.MARKETING]: marketingMenus(navigate),
      [UserRole.CUSTOMER_CARE]: customerCareMenus(navigate),
      [UserRole.PARTNER]: partnerMenus(navigate),
    } as Record<UserRole, ItemType[]>
  )[role];
};

export const getOpenSideMenuKeys = () => {
  const opened: string[] = [];
  const path = window.location.pathname;

  for (const key in menuKeysPaths) {
    if (path.includes(key)) {
      opened.push(menuKeysPaths[key]);
    }
  }

  return opened;
};
