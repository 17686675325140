import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, notification, TablePaginationConfig, Upload } from "antd";
import { UploadChangeParam, UploadFile } from "antd/es/upload";
import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  api,
  defaultAPIErrorHandler,
  IStore,
  IPaginatedList,
} from "../../../../services/api";
import StoresError from "./error";
import StoresTable from "./table";
// @ts-ignore
import PlanilhaModeloPDV from "../../../../downloads/PlanilhaModeloPDV.csv";
import BackButton from "../../../../components/back-button";

const Stores = () => {
  const [filters, setFilters] = React.useState({
    page: 0,
  });
  const { company_id } = useParams<{ company_id: string }>();
  const [items, setItems] = React.useState<IStore[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);
  const [errorItems, setErrorItems] = React.useState<IStore[] | undefined>();
  const [file, setFile] = React.useState<UploadFile | null>(null);

  const navigate = useNavigate();

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  const handleFileUpload = React.useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.file.type !== "text/csv" || info.fileList.length === 0) {
        setFile(null);
        return;
      }

      setFile(info.file);
    },
    []
  );

  const handleApiUpload = () => {
    var bodyFormData = new FormData();

    bodyFormData.append("file", file?.originFileObj as File);

    setLoading(true);
    api
      .post<IPaginatedList<IStore>>(
        `/companies/store/${company_id}`,
        bodyFormData,
        {
          headers: { "Content-Type": "multipart/form-data" },
        }
      )
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
        setErrorItems(response.data.errors);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setFile(null);
        setLoading(false);
      });
  };

  React.useEffect(() => {
    if (!company_id || !+company_id) {
      notification.error({
        message: "Empresa não encontrado",
      });
      return navigate("/admin/companies");
    }

    setLoading(true);
    setItems([]);
    api
      .get<IPaginatedList<IStore>>(`/companies/store/${company_id}`, {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, company_id, onChangePagination, navigate]);

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <BackButton
          title={`Administração > Parceiros > PDVs ${
            items[0]?.empresa?.nome_fantasia ?? ""
          }`}
          pathToNavigate="/admin/companies"
          showConfirmation
        />
      </div>

      <a href={PlanilhaModeloPDV} download={true}>
        <Button style={{ margin: 16 }} icon={<DownloadOutlined />}>
          Baixar CSV modelo
        </Button>
      </a>

      <Upload
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
            onSuccess?.("ok");
          }, 0);
        }}
        fileList={file ? [file] : []}
        multiple={false}
        onRemove={() => setFile(null)}
        onChange={handleFileUpload}
      >
        <Button icon={<UploadOutlined />}>Importar Arquivo de PDV(s)</Button>
      </Upload>

      <Button
        type="primary"
        onClick={handleApiUpload}
        disabled={file === null || loading}
        style={{ margin: 16 }}
      >
        {loading ? "Enviando" : "Enviar"}
      </Button>

      {errorItems && errorItems.length > 0 && <StoresError data={errorItems} />}

      <StoresTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default Stores;
