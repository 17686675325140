import { Button, Form, notification, Select, TablePaginationConfig, UploadFile } from "antd";
import React from "react";
import Upload, { UploadChangeParam } from "antd/es/upload";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { api, defaultAPIErrorHandler, IAppUsers, IContractor, IPaginatedList } from "../../../services/api";
import { useNavigate } from "react-router-dom";
import AppUsersTable from "./table";

const AppUsers = () => {

const [file, setFile] = React.useState<UploadFile | null>(null);
const [loading, setLoading] = React.useState(false);

const [items, setItems] = React.useState<IAppUsers[]>([]);
const [paginationConfig, setPaginationConfig] =
  React.useState<TablePaginationConfig>({});
const [filters, setFilters] = React.useState({
  page: 0,
});

const [corporates, setCorporates] = React.useState<IContractor[]>([]);

const onChangePagination = React.useCallback(
  (page: number, _pageSize?: number) => {
    setFilters({
      ...filters,
      page: page - 1,
    });
  },
  [filters]
);

const navigate = useNavigate();

const listCorporates = React.useCallback(() => {
  return api
    .get<IContractor[]>("/contractors")
    .then((response) => {
      response.data.splice(0, 1);
      setCorporates(response.data);
    })
    .catch(defaultAPIErrorHandler)
    .finally(() => setLoading(false));
}, []);

React.useEffect(() => {
  listCorporates();
}, [listCorporates]);

const searchHistory = React.useCallback(() => {
  setLoading(true);
  setItems([]);
  api
    .get<IPaginatedList<IAppUsers>>("/app-users", {
      params: filters,
    })
    .then((response) => {
      setPaginationConfig({
        current: response.data.page + 1,
        total: response.data.totalItems,
        pageSize: response.data.itemsPerPage,
        onChange: onChangePagination,
      });
      setItems(response.data.items);
    })
    .catch(defaultAPIErrorHandler)
    .finally(() => setLoading(false));
}, [filters, onChangePagination]);

React.useEffect(() => {
  searchHistory();
}, [searchHistory]);

const handleUpload = React.useCallback(
  (info: UploadChangeParam<UploadFile>) => {
    if (info.fileList.length === 0) {
      setFile(null);
      return;
    }

    const allowedTypes = ["text/csv"];

    if (!allowedTypes.includes(info.file?.type || "")) {
      notification.error({
        message: "O arquivo deve ser do tipo " + allowedTypes.join(", "),
      });
      setFile(null);
      return;
    }

    setFile(info.file);
  },
  []
);

const handleSubmit = React.useCallback(
  (data: {
    file_info: UploadChangeParam<UploadFile>;
    corporate: string;
  }) => {
    if (!file) {
      notification.error({
        message: "Por favor, selecione uma imagem!",
      });
      return;
    }

    setLoading(true);
    var bodyFormData = new FormData();

    bodyFormData.append("file", data.file_info.file.originFileObj as File);
    bodyFormData.append(
      "corporate",
      data.corporate
    );

    api
      .post("/app-users/batch-insert", bodyFormData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((data) => {

        notification.info({
          message: "Inserindo usuários! Você receberá uma notificação quando for concluído!",
        });

        setTimeout(() => navigate(0), 2000);

        return navigate("/admin/app-users");
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setLoading(false);
      });
  },
  [navigate, file]
);

return (
  <div>
    <h1 style={{ marginRight: 10 }}>Usuários do app</h1>
    <Form
      name="form-batch-insert"
      labelCol={{ span: 4 }}
      wrapperCol={{ span: 14 }}
      layout="horizontal"
      onFinish={handleSubmit}
      style={{ maxWidth: 600, minWidth: 100 }}
    >
      <Form.Item
        label="Contratante"
        name="corporate"
        rules={[
          {
            required: true,
            message: "Por favor, selecione um contratante!",
          },
      ]}>
      <Select>
        {corporates.map((coporate) => (
          <Select.Option value={coporate.id}>{coporate.name}</Select.Option>
        ))}
      </Select>
      </Form.Item>

      <Form.Item
        label="Arquivo csv"
        name="file_info"
        rules={[
        {
          required: true,
          message: "Por favor, selecione um arquivo!",
        }]}
      >
      <Upload
        customRequest={({ onSuccess }) => {
          setTimeout(() => {
          onSuccess?.("ok");
          }, 0);
        }}
        fileList={file ? [file] : []}
        multiple={false}
        accept="text/csv"
        onPreview={() => {}}
        onChange={handleUpload}
      >
        {file ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
      </Upload>
      </Form.Item>

      <Form.Item>
        <Button type="primary" htmlType="submit">
          {loading ? <LoadingOutlined /> : "Salvar"}
        </Button>
      </Form.Item>
    </Form>

    <AppUsersTable
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
        corporates={corporates}
      />
  </div>
);
};

export default AppUsers;
