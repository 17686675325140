import React from "react";
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import "dayjs/locale/pt-br";
import { Form, Button, Upload, UploadFile, notification, Select } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import BackButton from "../../../../components/back-button";
import {
  IApiContractor,
  api,
  defaultAPIErrorHandler,
  getContractors,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import "./index.css";

const CreateWhitelistByFile = () => {
  const [file, setFile] = React.useState<UploadFile | null>(null);
  const [loading, setLoading] = React.useState(false);
  const [contractors, setContractors] = React.useState<IApiContractor[]>([]);
  const [selectedContractorId, setSelectedContractorId] = React.useState<
    string | null
  >(null);

  const navigate = useNavigate();

  const handleUpload = React.useCallback(
    (info: UploadChangeParam<UploadFile>) => {
      if (info.fileList.length === 0) {
        setFile(null);
        return;
      }

      const allowedTypes = ["text/csv"];

      if (!allowedTypes.includes(info.file?.type || "")) {
        notification.error({
          message: "A planilha deve ser do tipo " + allowedTypes.join(", "),
        });
        setFile(null);
        return;
      }

      setFile(info.file);
    },
    []
  );

  const handleDownloadExampleFile = React.useCallback(() => {
    api
      .get("/whitelist/files/download/example")
      .then((response) => {
        window.open(response.data?.signedUrl, "_blank");
      })
      .catch(defaultAPIErrorHandler);
  }, []);

  const handleSubmit = React.useCallback(
    (data: {
      file_info: UploadChangeParam<UploadFile>;
      destination_url?: string;
      starting_date: Date;
      final_date?: Date;
    }) => {
      if (!file) {
        notification.error({
          message: "Por favor, selecione um arquivo!",
        });
        return;
      }

      if (!selectedContractorId) {
        notification.error({
          message: "Por favor, selecione um contratante!",
        });
        return;
      }

      setLoading(true);
      var bodyFormData = new FormData();

      bodyFormData.append("file", data.file_info.file.originFileObj as File);
      bodyFormData.append("contractorId", selectedContractorId);

      api
        .post("/whitelist/files", bodyFormData, {
          headers: { "Content-Type": "multipart/form-data" },
        })
        .then(() => {
          notification.info({
            message: "Criação dos registros em andamento...",
          });

          return navigate("/admin/whitelist/files");
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, file, selectedContractorId]
  );

  React.useEffect(() => {
    getContractors().then(setContractors);
  }, []);

  return (
    <div>
      <BackButton
        title="Administração > Whitelist > Arquivos > Criar/atualizar"
        pathToNavigate="/admin/whitelist/files"
        showConfirmation
      />
      <h1>Criar/atualizar por arquivo</h1>
      Você pode criar/atualizar os registros da whitelist através de um arquivo
      .csv contendo os dados de usuário.
      <br />
      <br />
      <Button type="primary" onClick={handleDownloadExampleFile}>
        Baixar arquivo modelo
      </Button>
      <br />
      <br />
      <b>Observações:</b>
      <br />- Os únicos campos obrigatórios são <b>cpf</b> e <b>status.</b>
      <br />- O campo <b>status</b> deve ser preenchido com <b>ATIVO</b> ou{" "}
      <b>INATIVO</b>
      <br />- O campo <b>sexo</b> deve ser preenchido com <b>M</b> ou <b>F</b>
      <br />- O campo <b>datanascimento</b> deve ser preenchido no formato{" "}
      <b>dd/mm/aaaa</b>
      <br />- O campo <b>uf</b> deve ser preenchido com a sigla do estado. Ex:{" "}
      <b>MG</b>
      <br />- Se um CPF já existir na whitelist do contratante, ele{" "}
      <b>será atualizado</b> com as informações da planilha.
      <br />- O progresso da inserção dos registros pode ser acompanhado na
      página de arquivos de whitelist.
      <br />
      <br />
      <br />
      Selecione o contratante:
      <br />
      <Select
        onChange={(value) => setSelectedContractorId(value)}
        className="contractor-select"
      >
        {contractors.map((contractor) => (
          <Select.Option key={contractor.id} value={contractor.id}>
            {contractor.tradeName}
          </Select.Option>
        ))}
      </Select>
      <br />
      <br />
      <Form
        name="form-whitelist-file-create"
        layout="horizontal"
        onFinish={handleSubmit}
        style={{ maxWidth: 600, minWidth: 100 }}
        disabled={!selectedContractorId}
      >
        <Form.Item
          label="Arquivo"
          name="file_info"
          rules={[
            {
              required: true,
              message: "Por favor, selecione um arquivo!",
            },
          ]}
        >
          <Upload
            customRequest={({ onSuccess }) => {
              setTimeout(() => {
                onSuccess?.("ok");
              }, 0);
            }}
            fileList={file ? [file] : []}
            listType="picture"
            multiple={false}
            accept="text/csv"
            onPreview={() => {}}
            onChange={handleUpload}
          >
            {file ? null : <Button icon={<UploadOutlined />}>Upload</Button>}
          </Upload>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Enviar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateWhitelistByFile;
