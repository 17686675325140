import React from "react";
import { Navigate } from "react-router-dom";
import { defaultRouteByRole } from "../../components/sidemenu/roles";
import { useGlobal } from "../../hooks/global";
import { UserRole } from "../../services/api";

const Home: React.FC = () => {
  const { user } = useGlobal();

  return (
    <Navigate to={defaultRouteByRole[user?.role as UserRole] || "/login"} />
  );
};

export default Home;
