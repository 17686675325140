import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "dayjs/locale/pt-br";
import {
  Form,
  Button,
  Input,
  notification,
  Select,
  Modal,
} from "antd";
import BackButton from "../../../../components/back-button";
import dayjs from "dayjs";
import {
  api,
  defaultAPIErrorHandler,
  IApiContractor,
  IPlan,
} from "../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import "./index.css";

interface IEditContractorProps {
  publicName: string,
  tradeName: string,
  plan: string,
  primaryColor: string,
  secondaryColor: string
  migration: {
    walletApiId: 0
  },
  whitelistSource: string
}

const EditContractors = () => {
  const [loading, setLoading] = React.useState(false);
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [confirmedData, setConfirmedData] =
    React.useState<IEditContractorProps>();
  const { id } = useParams<{ id: string }>();
  const [contractor, setContractor] = React.useState<IApiContractor | null>(null);
  const [plans, setPlans] = React.useState<IPlan[]>([]);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!id) {
      notification.error({
        message: "Contratante não encontrado",
      });
      return navigate("/admin/contractors");
    }

    setLoading(true);
    api
      .get<IApiContractor>(`/contractors-api/${id}`)
      .then((response) => {
        setContractor(response.data);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [id, navigate]);

  React.useEffect(() => {
    setLoading(true);
    api
      .get<IPlan[]>(`/contractors-plans-api`)
      .then((response) => {
        setPlans(response.data);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [navigate])

  const handleSubmit = React.useCallback(
    (data: IEditContractorProps | undefined) => {
      if (loading) return;

      if (!data || !data.publicName || !data.tradeName) {
        return notification.error({
          message: "Por favor, preencha todos os campos!",
        });
      }

      if (!data.plan) {
        return notification.error({
          message: "Por favor, informe o plano!",
        });
      }

      const requestData = {
        publicName: data.publicName,
        tradeName: data.tradeName,
        planId: data.plan,
        theme: {
          primaryColor: data.primaryColor,
          secondaryColor: data.secondaryColor,
        },
      };

      setLoading(true);
      api
        .patch(`contractors-api/${id}`, requestData)
        .then(() => {
          notification.success({
            message: "Contratante alterado com sucesso!",
          });

          return navigate(`/admin/contractors/${id}/edit`);
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, loading, id]
  );

  if (!contractor)
    return (
      <>
        <BackButton
          title={`Administração > Contratantes > Nao encontrado > Editar`}
          pathToNavigate="/admin/contractors"
          showConfirmation
        />
        <h1>Editar Contratante</h1>
        <center>
          <LoadingOutlined />
        </center>
      </>
    );

  return (
    <div>
      <Modal
        title="Confirmação"
        open={showConfirmationModal}
        onOk={() => {
          handleSubmit(confirmedData);
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <p>Deseja mesmo editar o contratante?</p>
      </Modal>
      <BackButton
        title={`Administração > Contratantes > ${contractor?.publicName} > Editar`}
        pathToNavigate="/admin/contractors"
        showConfirmation
      />
      <h1>Editar Contratante</h1>

      <Form
        name="form-contractor-edit"
        labelWrap={true}
        layout="vertical"
        onFinish={(data) => {
          setShowConfirmationModal(true);
          setConfirmedData(data);
        }}
        style={{ maxWidth: 550, minWidth: 100 }}
        initialValues={{
          cnpj: contractor.cnpj,
          publicName: contractor.publicName,
          tradeName: contractor.tradeName,
          plan: contractor.plan.id,
          active: contractor.active,
          primaryColor: contractor.theme.primaryColor,
          secondaryColor: contractor.theme.secondaryColor,
          contractStartDate: dayjs(contractor.contractStartDate).format("DD/MM/YYYY HH:mm"),
          contractEndDate: dayjs(contractor?.contractEndDate).format("DD/MM/YYYY HH:mm"),
        }}
      >
        <Form.Item label="CNPJ" name="cnpj">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Nome Fantasia" name="publicName">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Razão Social" name="tradeName">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Plano" name="plan">
          <Select>
            {plans.map((plan) => (
              <Select.Option value={plan.id}>{plan.name}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Cor Primária" name="primaryColor" className="color-picker">
          <Input type="color"  disabled />
        </Form.Item>

        <Form.Item label="Cor Secundária" name="secondaryColor" className="color-picker">
          <Input type="color" disabled />
        </Form.Item>

        <Form.Item label="Data de inicio do contrato" name="contractStartDate">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Data de fim do contrato" name="contractEndDate">
          <Input disabled />
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  )
};

export default EditContractors;
