import React from "react";
import {
  api,
  ILoginRequestBody,
  ILoginResponseBody,
  INotification,
  IPaginatedList,
  setApiToken,
} from "../services/api";
import { IGlobalContext, IUser, ILoginProps } from "./global.structures";

const GlobalContext: React.Context<IGlobalContext> =
  React.createContext<IGlobalContext>({
    user: null,
    login: async () => false,
    logout: () => {},
    notifications: [],
    fetchNotifications: async () => {},
  });

const localStorageTokenKey = "@bo:token";
const localStorageUserIdKey = "@bo:user:id";

export const GlobalProvider = ({ children }: { children: React.ReactNode }) => {
  const [user, setUser] = React.useState<IUser | null>(null);
  const [notifications, setNotifications] = React.useState<INotification[]>([]);

  const fetchNotifications = React.useCallback(async () => {
    if (!user) return;

    try {
      const { data } = await api.get<IPaginatedList<INotification>>(
        `/notifications`,
        {
          params: {
            read: false,
          },
        }
      );
      setNotifications(data.items);
    } catch {
      // ignore
    }
  }, [user]);

  React.useEffect(() => {
    const timeToFetchNotifications = 1 * 60 * 1000; // 2 minutes
    const notificationsInterval = setInterval(
      fetchNotifications,
      timeToFetchNotifications
    );

    fetchNotifications();

    return () => {
      clearInterval(notificationsInterval);
    };
  }, [fetchNotifications]);

  const login = async (props?: ILoginProps): Promise<boolean> => {
    const { email, password } = props || {};

    if (user) {
      return true;
    }

    const existingToken = localStorage.getItem(localStorageTokenKey);
    const existingUserId = localStorage.getItem(localStorageUserIdKey);

    try {
      if (email && password) {
        const { data } = await api.post<ILoginResponseBody>(
          `/users/authenticate`,
          {
            email: email.trim(),
            password,
          } as ILoginRequestBody
        );

        localStorage.setItem(localStorageTokenKey, data.access_token);
        localStorage.setItem(localStorageUserIdKey, data.user.id);

        setApiToken(data.access_token);
        setUser(data.user);

        return true;
      } else if (existingToken && existingUserId) {
        setApiToken(existingToken);

        const { data } = await api.get<IUser>(`/users/${existingUserId}`);
        setUser(data);

        return true;
      }

      return false;
    } catch (e) {
      logout();
      return false;
    }
  };

  const logout = () => {
    localStorage.removeItem(localStorageTokenKey);
    localStorage.removeItem(localStorageUserIdKey);
    setUser(null);
    setApiToken("");
  };

  return (
    <GlobalContext.Provider
      value={{
        user,
        login,
        logout,
        notifications,
        fetchNotifications,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export function useGlobal() {
  const context = React.useContext(GlobalContext);
  return context;
}
