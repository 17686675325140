import { TablePaginationConfig } from "antd";
import React from "react";
import {
  api,
  defaultAPIErrorHandler,
  IReport,
  IPaginatedList,
} from "../../../services/api";
import ReportsCards from "./table";

const Reports = () => {
  const [filters, setFilters] = React.useState({
    page: 0,
  });
  const [items, setItems] = React.useState<IReport[]>([]);
  const [paginationConfig, setPaginationConfig] =
    React.useState<TablePaginationConfig>({});
  const [loading, setLoading] = React.useState(false);

  const onChangePagination = React.useCallback(
    (page: number, _pageSize?: number) => {
      setFilters({
        ...filters,
        page: page - 1,
      });
    },
    [filters]
  );

  const searchReports = React.useCallback(() => {
    setLoading(true);
    setItems([]);
    api
      .get<IPaginatedList<IReport>>("/reports", {
        params: filters,
      })
      .then((response) => {
        setPaginationConfig({
          current: response.data.page + 1,
          total: response.data.totalItems,
          pageSize: response.data.itemsPerPage,
          onChange: onChangePagination,
        });
        setItems(response.data.items);
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  }, [filters, onChangePagination]);

  React.useEffect(() => {
    searchReports();
  }, [searchReports]);

  return (
    <div>
      <ReportsCards
        paginationConfig={paginationConfig}
        data={items}
        loading={loading}
      />
    </div>
  );
};

export default Reports;
