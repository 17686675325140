import { Card, List } from "antd";

const SegmentsList = () => {
  const segments = [
    {
      id: 1,
      nome: "ACADEMIAS",
    },
    {
      id: 2,
      nome: "COMÉRCIOS",
    },
    {
      id: 3,
      nome: "BELEZA E ESTÉTICA",
    },
    {
      id: 4,
      nome: "AUTO ESCOLAS",
    },
    {
      id: 5,
      nome: "DROGARIAS",
    },
    {
      id: 6,
      nome: "ALIMENTAÇÃO",
    },
    {
      id: 7,
      nome: "EDUCAÇÃO",
    },
    {
      id: 8,
      nome: "ÓTICAS",
    },
    {
      id: 9,
      nome: "SERVIÇOS",
    },
    {
      id: 10,
      nome: "SAÚDE",
    },
    {
      id: 11,
      nome: "POSTO DE COMBUSTÍVEL",
    },
    {
      id: 12,
      nome: "VESTUÁRIO",
    },
  ];

  return (
    <List
      grid={{ gutter: 16, column: 4 }}
      dataSource={segments}
      renderItem={(item) => (
        <List.Item>
          <Card title={item.id}>{item.nome}</Card>
        </List.Item>
      )}
    />
  );
};

export default SegmentsList;
