import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "dayjs/locale/pt-br";
import { Form, Button, Input, notification, Select, Switch, Modal } from "antd";
import BackButton from "../../../../components/back-button";
import { Dayjs } from "dayjs";
import {
  api,
  CampaignType,
  CampaignVariableInputType,
  defaultAPIErrorHandler,
  marketingApi,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import CampaignAudiences from "../../../../components/audiences";

interface ICreateCampaignProps {
  type: CampaignType;
  title: string;
  content: string;
  executionDate?: Dayjs;
  data?: string;
}

const CreateCampaign = () => {
  const [loading, setLoading] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [audiences, setAudiences] = React.useState<CampaignVariableInputType[]>(
    []
  );
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [confirmedData, setConfirmedData] =
    React.useState<ICreateCampaignProps>();
  const [unsavedAudience, setUnsavedAudience] = React.useState(false);

  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    (data: ICreateCampaignProps | undefined) => {
      if (loading) return;

      if (unsavedAudience) {
        return notification.error({
          message: "Por favor, salve a audiência antes de criar a campanha!",
        });
      }

      if (!data || !data.title || !data.type || !data.content) {
        return notification.error({
          message: "Por favor, preencha todos os campos!",
        });
      }

      if (showData) {
        if (!data.data) {
          return notification.error({
            message: "Por favor, preencha os dados!",
          });
        }

        try {
          data.data = JSON.parse(data.data);
        } catch (error) {
          return notification.error({
            message: "Os dados não estão em formato JSON válido!",
          });
        }
      }

      const audiencesWithoutId = audiences.map((audience) => {
        const { id, ...rest } = audience;
        return rest;
      });

      const requestData = {
        ...data,
        audiences: audiencesWithoutId,
      };

      setLoading(true);
      marketingApi
        .post("/campaigns", requestData)
        .then(() => {
          notification.success({
            message: "Campanha criada com sucesso!",
          });

          return navigate("/marketing/campaigns");
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, loading, showData, audiences, unsavedAudience]
  );

  return (
    <div>
      <Modal
        title="Confirmação"
        open={showConfirmationModal}
        onOk={() => {
          handleSubmit(confirmedData);
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <p>
          Deseja mesmo criar a campanha? A status poderá ser acompanhado na tela
          inicial de campanhas.
        </p>
      </Modal>
      <BackButton
        title="Marketing > Campanhas > Criar"
        pathToNavigate="/marketing/campaigns"
        showConfirmation
      />
      <h1>Criar nova campanha</h1>

      <Form
        name="form-campaign-create"
        labelWrap={true}
        layout="vertical"
        onFinish={(data) => {
          setShowConfirmationModal(true);
          setConfirmedData(data);
        }}
        style={{ maxWidth: 550, minWidth: 100 }}
        initialValues={{
          type: CampaignType.PUSH,
        }}
      >
        <Form.Item
          wrapperCol={{ span: 12 }}
          label="Tipo"
          name="type"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o tipo da campanha!",
            },
          ]}
        >
          <Select>
            <Select.Option value="PUSH">Notificação Push</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Por favor, insira o título da campanha!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Mensagem"
          name="content"
          rules={[
            {
              required: true,
              message: "Por favor, insira a mensagem da campanha!",
            },
          ]}
        >
          <Input.TextArea />
        </Form.Item>

        <CampaignAudiences
          audiences={audiences}
          setAudiences={setAudiences}
          setUnsavedAudience={setUnsavedAudience}
        />

        <Form.Item label="Enviar dados">
          <Switch
            checked={showData}
            defaultChecked={false}
            onChange={(checked) => setShowData(checked)}
          />
        </Form.Item>

        {showData && (
          <Form.Item label={"Dados\npersonalizados (JSON)"} name="data">
            <Input.TextArea />
          </Form.Item>
        )}

        <br />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Criar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateCampaign;
