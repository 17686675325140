import React from 'react';
import { Button, Form, FormInstance, Input } from 'antd';

const ValidationHeaderSearch = ({
  onSearch,
}: {
  onSearch: (cpf: string) => void;
}) => {
  const formRef = React.useRef<FormInstance>(null);

  const handleSubmit = React.useCallback(
    async (data: { cpf: string }) => {
      onSearch(data.cpf);
    },
    [onSearch]
  );

  return (
    <Form
      name="control-ref"
      layout="inline"
      onFinish={handleSubmit}
      style={{ margin: '1%' }}
      ref={formRef}
    >
      <Form.Item
        label="CPF"
        name="cpf"
        rules={[
          {
            len: 11,
            required: true,
          },
        ]}
      >
        <Input />
      </Form.Item>

      <br />
      <Form.Item wrapperCol={{ offset: 2 }}>
        <Button type="primary" htmlType="submit">
          Validar
        </Button>
      </Form.Item>
    </Form>
  );
};

export default ValidationHeaderSearch;
