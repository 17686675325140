import React from "react";
import { LoadingOutlined } from "@ant-design/icons";
import "dayjs/locale/pt-br";
import { Form, Button, Input, notification, Select, Switch, Modal } from "antd";
import BackButton from "../../../../components/back-button";
import dayjs, { Dayjs as DayjsType } from "dayjs";
import {
  api,
  CampaignStatus,
  CampaignType,
  CampaignVariableInputType,
  defaultAPIErrorHandler,
  ICampaign,
  marketingApi,
} from "../../../../services/api";
import { useNavigate, useParams } from "react-router-dom";
import CampaignAudiences from "../../../../components/audiences";

interface IEditCampaignProps {
  type?: CampaignType;
  title?: string;
  content?: string;
  status?: CampaignStatus;
  executionDate?: DayjsType;
  data?: string;
}

const EditCampaign = () => {
  const [loading, setLoading] = React.useState(false);
  const [showData, setShowData] = React.useState(false);
  const [audiences, setAudiences] = React.useState<CampaignVariableInputType[]>(
    []
  );
  const [showConfirmationModal, setShowConfirmationModal] =
    React.useState(false);
  const [confirmedData, setConfirmedData] =
    React.useState<IEditCampaignProps>();
  const { id } = useParams<{ id: string }>();
  const [campaign, setCampaign] = React.useState<ICampaign | null>(null);
  const [unsavedAudience, setUnsavedAudience] = React.useState(false);
  const [canEditFields, setCanEditFields] = React.useState(false);

  const navigate = useNavigate();

  React.useEffect(() => {
    if (!id) {
      notification.error({
        message: "Campanha não encontrada",
      });
      return navigate("/marketing/campaigns");
    }

    setLoading(true);
    marketingApi
      .get<ICampaign>(`/campaigns/${id}`)
      .then((response) => {
        setCampaign(response.data);
        setShowData(!!response.data.data);
        setAudiences(
          response.data.audiences
            ? response.data.audiences.map((value, index) => {
                return {
                  id: index,
                  ...value,
                };
              })
            : []
        );
        setCanEditFields(
          response.data?.status === CampaignStatus.WAITING &&
            !!response.data?.executionDate
        );
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => {
        setLoading(false);
      });
  }, [id, navigate]);

  const handleSubmit = React.useCallback(
    (data: IEditCampaignProps | undefined) => {
      if (loading) return;

      if (unsavedAudience) {
        return notification.error({
          message: "Por favor, salve a audiência antes de salvar a campanha!",
        });
      }

      if (!data || !data.title || !data.type || !data.content) {
        return notification.error({
          message: "Por favor, preencha todos os campos!",
        });
      }

      if (!data.executionDate) {
        return notification.error({
          message: "Por favor, preencha a data de execução!",
        });
      }

      if (showData) {
        if (!data.data) {
          return notification.error({
            message: "Por favor, preencha os dados!",
          });
        }

        try {
          data.data = JSON.parse(data.data);
        } catch (error) {
          return notification.error({
            message: "Os dados não estão em formato JSON válido!",
          });
        }
      }

      const audiencesWithoutId = audiences.map((audience) => {
        const { id, ...rest } = audience;
        return rest;
      });
      const shouldUpdateStatus =
        data.status === CampaignStatus.DONE && data.status !== campaign?.status;

      const requestData = {
        title: data.title,
        content: data.content,
        ...(showData && {
          data: data.data,
        }),
        audiences: audiencesWithoutId,
        ...(shouldUpdateStatus && {
          status: data.status,
        }),
      };

      setLoading(true);
      api
        .patch(`/marketing/campaigns/${campaign?.id}`, requestData)
        .then(() => {
          notification.success({
            message: "Campanha alterada com sucesso!",
          });

          return navigate("/marketing/campaigns");
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => {
          setLoading(false);
        });
    },
    [navigate, loading, showData, audiences, unsavedAudience, campaign]
  );

  if (!campaign)
    return (
      <>
        <BackButton
          title={`Marketing > Campanhas > ${id} > Editar`}
          pathToNavigate="/marketing/banners"
          showConfirmation
        />
        <h1>Editar Campanha</h1>
        <center>
          <LoadingOutlined />
        </center>
      </>
    );

  return (
    <div>
      <Modal
        title="Confirmação"
        open={showConfirmationModal}
        onOk={() => {
          handleSubmit(confirmedData);
          setShowConfirmationModal(false);
        }}
        onCancel={() => setShowConfirmationModal(false)}
        cancelText="Cancelar"
        okText="Confirmar"
      >
        <p>Deseja mesmo editar a campanha?</p>
      </Modal>
      <BackButton
        title={`Marketing > Campanhas > ${id} > Editar`}
        pathToNavigate="/marketing/campaigns"
        showConfirmation
      />
      <h1>Visualizar campanha</h1>

      <Form
        name="form-campaign-edit"
        labelWrap={true}
        layout="vertical"
        onFinish={(data) => {
          setShowConfirmationModal(true);
          setConfirmedData(data);
        }}
        style={{ maxWidth: 550, minWidth: 100 }}
        initialValues={{
          type: campaign.type,
          title: campaign.title,
          content: campaign.content,
          executionDate: campaign.executionDate
            ? dayjs(campaign.executionDate)
            : undefined,
          data: campaign.data ? JSON.stringify(campaign.data) : undefined,
          status: campaign.status,
          createdAt: dayjs(campaign.createdAt).format("DD/MM/YYYY HH:mm"),
          estimatedRecipients: campaign.estimatedRecipients,
        }}
      >
        <Form.Item label="Data de criação da campanha" name="createdAt">
          <Input disabled />
        </Form.Item>

        <Form.Item label="Recebedores estimados" name="estimatedRecipients">
          <Input disabled />
        </Form.Item>

        <Form.Item wrapperCol={{ span: 12 }} label="Status" name="status">
          <Select disabled={!canEditFields}>
            <Select.Option value="WAITING">Aguardando</Select.Option>
            <Select.Option value="DONE">Finalizado</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          wrapperCol={{ span: 12 }}
          label="Tipo"
          name="type"
          rules={[
            {
              required: true,
              message: "Por favor, selecione o tipo da campanha!",
            },
          ]}
        >
          <Select disabled>
            <Select.Option value="PUSH">Notificação Push</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Título"
          name="title"
          rules={[
            {
              required: true,
              message: "Por favor, insira o título da campanha!",
            },
          ]}
        >
          <Input disabled={!canEditFields} />
        </Form.Item>
        <Form.Item
          label="Mensagem"
          name="content"
          rules={[
            {
              required: true,
              message: "Por favor, insira a mensagem da campanha!",
            },
          ]}
        >
          <Input.TextArea disabled={!canEditFields} />
        </Form.Item>

        <CampaignAudiences
          audiences={audiences}
          setAudiences={setAudiences}
          setUnsavedAudience={setUnsavedAudience}
          canEditFields={canEditFields}
        />

        <Form.Item label="Enviar dados">
          <Switch
            checked={showData}
            disabled={!canEditFields}
            defaultChecked={false}
            onChange={(checked) => setShowData(checked)}
          />
        </Form.Item>

        {showData && (
          <Form.Item label={"Dados personalizados (JSON)"} name="data">
            <Input.TextArea disabled={!canEditFields} />
          </Form.Item>
        )}

        <br />
        <Form.Item>
          <Button type="primary" htmlType="submit" disabled={!canEditFields}>
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditCampaign;
