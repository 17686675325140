import React from 'react';
import { Layout } from 'antd';
import { ReactElement } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import App from './app';
import Header from './components/header';
import SideMenu from './components/sidemenu';
import { useGlobal } from './hooks/global';
import Home from './pages/home';
import Login from './pages/login';
import Banners from './pages/marketing/banners';
import CreateBanner from './pages/marketing/banners/create';
import EditBanner from './pages/marketing/banners/edit';
import Users from './pages/admin/users';
import EditUser from './pages/admin/users/edit';
import CreateUser from './pages/admin/users/create';
import ActionsHistory from './pages/admin/actions-history';
import Reports from './pages/admin/reports';
import Companies from './pages/admin/companies';
import Stores from './pages/admin/companies/stores';
import Campaigns from './pages/marketing/campaigns';
import CreateCampaign from './pages/marketing/campaigns/create';
import EditCampaign from './pages/marketing/campaigns/edit';
import AppUsers from './pages/admin/app-users';
import ApiAccess from './pages/admin/api-access';
import Contractors from './pages/admin/contractors';
import EditContractors from './pages/admin/contractors/edit';
import CreateWhitelistByFile from './pages/admin/whitelist/create-by-file';
import WhitelistFiles from './pages/admin/whitelist/list-files';
import Whitelist from './pages/admin/whitelist';
import CreateWhitelistManually from './pages/admin/whitelist/create-manually';
import SearchWhitelistCpf from './pages/admin/whitelist/search-cpf';
import Validation from './pages/partner/validation';

const ProtectedRoute = ({
  children,
}: {
  children: ReactElement;
}): ReactElement => {
  const { user, login } = useGlobal();
  const [canAccess, setCanAccess] = React.useState(false);
  const [loginVerified, setLoginVerified] = React.useState(false);

  React.useEffect(() => {
    if (!user) {
      login()
        .then((result) => {
          if (result) {
            setCanAccess(true);
          } else {
            setCanAccess(false);
          }
        })
        .finally(() => setLoginVerified(true));
    } else {
      setCanAccess(true);
      setLoginVerified(true);
    }
  }, [login, user]);

  if (!loginVerified) {
    return <></>;
  }

  if (!canAccess) {
    return <Navigate to="/login" replace />;
  }

  return (
    <Layout>
      <Header />
      <Layout style={{ minHeight: '100vh' }}>
        <SideMenu mode="vertical" style={{ minWidth: 400 }} />
        <div style={{ padding: 30, overflow: 'auto', width: '100%' }}>
          {children}
        </div>
      </Layout>
    </Layout>
  );
};

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<App />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing/banners"
          element={
            <ProtectedRoute>
              <Banners />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing/banners/create"
          element={
            <ProtectedRoute>
              <CreateBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing/banners/:id/edit"
          element={
            <ProtectedRoute>
              <EditBanner />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing/campaigns"
          element={
            <ProtectedRoute>
              <Campaigns />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing/campaigns/create"
          element={
            <ProtectedRoute>
              <CreateCampaign />
            </ProtectedRoute>
          }
        />
        <Route
          path="/marketing/campaigns/:id/edit"
          element={
            <ProtectedRoute>
              <EditCampaign />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/users"
          element={
            <ProtectedRoute>
              <Users />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/users/:user_id/edit"
          element={
            <ProtectedRoute>
              <EditUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/users/create"
          element={
            <ProtectedRoute>
              <CreateUser />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/actions-history"
          element={
            <ProtectedRoute>
              <ActionsHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/reports"
          element={
            <ProtectedRoute>
              <Reports />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/companies"
          element={
            <ProtectedRoute>
              <Companies />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/companies/stores/:company_id"
          element={
            <ProtectedRoute>
              <Stores />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/app-users"
          element={
            <ProtectedRoute>
              <AppUsers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/api-access"
          element={
            <ProtectedRoute>
              <ApiAccess />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/contractors"
          element={
            <ProtectedRoute>
              <Contractors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/contractors/:id/edit"
          element={
            <ProtectedRoute>
              <EditContractors />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelist/files/create"
          element={
            <ProtectedRoute>
              <CreateWhitelistByFile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelist/files"
          element={
            <ProtectedRoute>
              <WhitelistFiles />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelist"
          element={
            <ProtectedRoute>
              <Whitelist />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelist/create"
          element={
            <ProtectedRoute>
              <CreateWhitelistManually />
            </ProtectedRoute>
          }
        />
        <Route
          path="/admin/whitelist/search"
          element={
            <ProtectedRoute>
              <SearchWhitelistCpf />
            </ProtectedRoute>
          }
        />
        <Route
          path="/partner/validation"
          element={
            <ProtectedRoute>
              <Validation />
            </ProtectedRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
}
