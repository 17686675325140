import { List } from "antd";
import React from "react";
import "./index.css";
import { useGlobal } from "../../hooks/global";
import {
  api,
  defaultAPIErrorHandler,
  INotification,
  NotificationEntity,
  NotificationStatus,
} from "../../services/api";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

export const notificationEntityMessageMapper = {
  [NotificationEntity.REPORT]: "O Relatório solicitado tem um novo status",
};

export const notificationEntityRouteMapper = {
  [NotificationEntity.REPORT]: "/admin/reports",
};

export const notificationStatusMapper = {
  [NotificationStatus.DONE]: "Concluído",
  [NotificationStatus.ERROR]: "Falha",
};

export const NotificationPanel = ({
  setOpen,
}: {
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const { notifications, fetchNotifications } = useGlobal();
  const [loading, setLoading] = React.useState(false);
  const navigate = useNavigate();

  const markAllAsRead = React.useCallback(async () => {
    setLoading(true);
    try {
      await api.patch("/notifications/all-read");
      setOpen(false);
      fetchNotifications();
    } catch (e) {
      defaultAPIErrorHandler(e);
    } finally {
      setLoading(false);
    }
  }, [fetchNotifications, setOpen]);

  const handleNotificationClick = React.useCallback(
    (notification: INotification) => {
      const routeToNavigate =
        notificationEntityRouteMapper[notification.entity];
      const currentRoute = window.location.pathname;

      if (currentRoute === routeToNavigate) {
        navigate(0);
      } else {
        navigate(notificationEntityRouteMapper[notification.entity]);
      }

      if (notification.read) return;
      api
        .patch(`/notifications/${notification.id}`, {
          read: true,
        })
        .then(fetchNotifications)
        .catch(() => {
          // ignore
        });
      setOpen(false);
    },
    [fetchNotifications, navigate, setOpen]
  );

  const notificationsLayout = React.useCallback(
    (item: INotification) => (
      <List.Item
        key={item.id}
        style={{ cursor: "pointer" }}
        onClick={() => handleNotificationClick(item)}
      >
        {item.message ? (
          <>{item.message}</>
        ) : (
          <>
            {notificationEntityMessageMapper[item.entity]}:{" "}
            {notificationStatusMapper[item.status]}{" "}
          </>
        )}
      </List.Item>
    ),
    [handleNotificationClick]
  );

  return (
    <div className="notification-container">
      <div className="notification-panel">
        {loading ? (
          <center>
            <LoadingOutlined />
          </center>
        ) : (
          <List
            locale={{ emptyText: "Sem notificações." }}
            dataSource={notifications}
            renderItem={notificationsLayout}
          />
        )}
      </div>
      {!loading && !!notifications.length && (
        <span className="notification-options" onClick={markAllAsRead}>
          Marcar tudo como lido
        </span>
      )}
    </div>
  );
};
