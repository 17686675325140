import React from "react";
import "dayjs/locale/pt-br";
import { LoadingOutlined } from "@ant-design/icons";
import {
  Form,
  Button,
  Input,
  notification,
  Select,
  Popover,
  FormInstance,
} from "antd";
import BackButton from "../../../../components/back-button";
import {
  api,
  defaultAPIErrorHandler,
  UserRole,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";
import { nanoid } from "nanoid";
import { roleReadableNames } from "../../../../components/sidemenu/roles";

const CreateUser = () => {
  const [loading, setLoading] = React.useState(false);
  const formRef = React.useRef<FormInstance>(null);
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    async (data: {
      name: string;
      email: string;
      role: Date;
      password?: string;
    }) => {
      setLoading(true);

      try {
        await api.post(`/users`, {
          name: data.name,
          email: data.email,
          role: data.role,
          password: data.password,
        });

        notification.success({
          message: "Usuário criado com sucesso!",
        });

        return navigate("/admin/users");
      } catch (error) {
        defaultAPIErrorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [navigate]
  );

  const generatePassword = () => {
    const password = nanoid();
    formRef.current?.setFieldsValue({ password });
    navigator.clipboard.writeText(password);
  };

  return (
    <div>
      <BackButton
        title={`Administração > Usuários > Criar`}
        pathToNavigate="/admin/users"
        showConfirmation
      />
      <h1>Criar Usuário</h1>
      <br />
      <Form
        name="control-ref"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        layout="horizontal"
        onFinish={handleSubmit}
        style={{ maxWidth: 600, minWidth: 150 }}
        ref={formRef}
      >
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              min: 3,
              required: true,
              message: "O nome é obrigatório e deve mais de 3 caracteres!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "O email inserido não é válido!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Senha"
          name="password"
          style={{ marginBottom: 2 }}
          rules={[
            {
              required: true,
              min: 8,
              message: "A senha inserida deve ter 8 ou mais caracteres!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item wrapperCol={{ offset: 4 }}>
          <Popover
            content={"A senha gerada foi copiada."}
            trigger="click"
            placement="right"
            mouseLeaveDelay={0}
          >
            <Button onClick={generatePassword}>Gerar aleatória</Button>
          </Popover>
        </Form.Item>

        <Form.Item
          label="Perfil"
          name="role"
          rules={[
            {
              required: true,
              message: "O tipo de perfil é obrigatório!",
            },
          ]}
        >
          <Select>
            {Object.keys(UserRole).map((role) => (
              <Select.Option key={role} value={role}>
                {roleReadableNames[role as UserRole]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <br />
        <Form.Item wrapperCol={{ offset: 2 }}>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default CreateUser;
