import React from "react";
import { Layout, Form, Input, Button, notification } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "../../hooks/global";
import { defaultAPIErrorHandler } from "../../services/api";
import { LoadingOutlined } from "@ant-design/icons";

const { Content } = Layout;

const Login: React.FC = () => {
  const navigate = useNavigate();
  const { login } = useGlobal();
  const [loading, setLoading] = React.useState(false);

  const authenticate = React.useCallback(
    (email: string, password: string) => {
      setLoading(true);
      login({ email, password })
        .then((response) => {
          if (response) {
            return navigate("/home");
          }

          notification.error({
            message: "Erro ao fazer login",
            description: "Não foi possível acessar com os dados informados.",
          });
        })
        .catch(defaultAPIErrorHandler)
        .finally(() => setLoading(false));
    },
    [login, navigate]
  );

  return (
    <Layout className="layout">
      <img
        src="/logo.png"
        alt="logo"
        width="179px"
        height="37px"
        style={{ alignSelf: "center", margin: 50 }}
      />
      <Content className="content-box">
        <p className="form-title">Acesso ao Backoffice</p>
        <div className="site-layout-content">
          <Form
            name="form-login"
            style={{ maxWidth: 400, width: "100%", minWidth: 200 }}
            onFinish={(values) => authenticate(values.email, values.password)}
            autoComplete="on"
          >
            <Form.Item
              label="E-mail"
              name="email"
              labelCol={{ span: 24 }}
              rules={[
                { required: true, message: "Por favor, preencha o e-mail!" },
              ]}
            >
              <Input />
            </Form.Item>

            <Form.Item
              label="Senha"
              name="password"
              labelCol={{ span: 24 }}
              rules={[
                { required: true, message: "Por favor, preencha a senha!" },
              ]}
            >
              <Input.Password />
            </Form.Item>

            <Form.Item>
              <center>
                <Button type="primary" htmlType="submit">
                  {loading ? <LoadingOutlined /> : "Entrar"}
                </Button>
              </center>
            </Form.Item>
          </Form>
        </div>
      </Content>
    </Layout>
  );
};

export default Login;
