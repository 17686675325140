import { Result, Typography } from "antd";
import "./index.css";
import { CloseCircleOutlined } from "@ant-design/icons";
import { ICompany } from "../../../../services/api";

const { Paragraph, Text } = Typography

const CompaniesError = ({
    data,
}: {
    data: ICompany[];
}) => {

    return (
        <Result
            status="error"
            title="Falha de cadastro"
            subTitle="Foram encontrados dados de lojas que impossibilitaram o cadastro no sistema."
        >
            <div className="desc">
                <Paragraph>
                    <Text
                        strong
                        style={{
                            fontSize: 16,
                        }}
                    >
                        Por favor verifique abaixo as Empresas que não foram inseridas:
                    </Text>
                </Paragraph>
                {data.map(company => <Paragraph>
                    <Paragraph>
                        <CloseCircleOutlined className="site-result-demo-error-icon" /> {company.nome_fantasia} / {company.cep}
                    </Paragraph>
                    <Text>{`O código de IBGE do ${company.municipio_id ? 'município' : 'estado'} não foi encontrado pelo nosso sistema, por favor verifique se há algum erro de digitação, caso contrário entre em contado com o departamento de tecnologia da Ducash`}</Text>
                </Paragraph>)}
            </div>
        </Result>
    );
};

export default CompaniesError;
