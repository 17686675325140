import React from "react";
import { Button, Table, TablePaginationConfig } from "antd";
import { IApiContractor } from "../../../../services/api";
import "./index.css";
import { useNavigate } from "react-router-dom";

const ContractorsTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IApiContractor[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();
  const columns = React.useMemo(
    () => [
      {
        title: "Logo",
        render: (_value: unknown, record: IApiContractor, _index: number) => (
          <img src={record.logo || ""} alt="" style={{ maxWidth: 128 }} />
        ),
        dataIndex: "logo",
        key: "logo",
      },
      {
        title: "CNPJ",
        dataIndex: "cnpj",
        key: "cnpj",
      },
      {
        title: "Razão Social",
        dataIndex: "tradeName",
        key: "tradeName",
      },
      {
        title: "Nome Fantasia",
        dataIndex: "publicName",
        key: "public_name",
      },
      {
        title: "Plano",
        dataIndex: "plan",
        render: (_value: unknown, record: IApiContractor, _index: number) => (
          <span>{record.plan?.name}</span>
        ),
        key: "plan",
      },
      {
        title: "Ações",
        render: (_value: unknown, record: IApiContractor, _index: number) => (
          <Button
            onClick={() => navigate(`/admin/contractors/${record.id}/edit`)}
          >
            Editar Contratante
          </Button>
        ),
        key: "edit",
      },
    ],
    [navigate]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ ...paginationConfig, showSizeChanger: false }}
      columns={columns}
      className="table-contractors"
      loading={loading}
      rowKey="cnpj"
    />
  );
};

export default ContractorsTable;
