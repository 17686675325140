import { Card } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";

import {
  FileAddOutlined,
  SearchOutlined,
  UserAddOutlined,
} from "@ant-design/icons";

const Whitelist = () => {
  const navigate = useNavigate();

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <h1 style={{ marginRight: 10 }}>Whitelist</h1>
      </div>
      O whitelist concede aos usuários que possuem o CPF cadastrado{" "}
      <b>acesso ao aplicativo</b> e aos{" "}
      <b>descontos em estabelecimentos parceiros.</b>
      <br />
      <br />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        <Card
          hoverable
          onClick={() => navigate("/admin/whitelist/search")}
          style={{
            width: 240,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <SearchOutlined
              style={{
                fontSize: "70px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </div>
          <div style={{ fontWeight: "bold", textAlign: "center" }}>
            Pesquisar
          </div>
          <div style={{ fontSize: "12px", textAlign: "center" }}>
            Pesquise um CPF para verificar se está na whitelist e a quais
            contratantes pertence
          </div>
        </Card>
        <Card
          hoverable
          onClick={() => navigate("/admin/whitelist/files")}
          style={{
            width: 240,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginRight: 20,
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <FileAddOutlined
              style={{
                fontSize: "70px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </div>
          <div style={{ fontWeight: "bold", textAlign: "center" }}>
            Arquivos
          </div>
          <div style={{ fontSize: "12px", textAlign: "center" }}>
            Envie um arquivo para atualizar/criar múltiplos registros ou
            acompanhe status
          </div>
        </Card>
        <Card
          hoverable
          onClick={() => navigate("/admin/whitelist/create")}
          style={{
            width: 240,
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              marginBottom: 10,
            }}
          >
            <UserAddOutlined
              style={{
                fontSize: "70px",
                alignSelf: "center",
                textAlign: "center",
              }}
            />
          </div>
          <div style={{ fontWeight: "bold", textAlign: "center" }}>
            Inserção Manual
          </div>
          <div style={{ fontSize: "12px", textAlign: "center" }}>
            Insira ou atualize um registro de CPF da whitelist manualmente
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Whitelist;
