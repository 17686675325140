import React from "react";
import { Menu } from "antd";
import { MenuProps } from "antd/es/menu/index";
import Sider from "antd/es/layout/Sider";
import { useGlobal } from "../../hooks/global";
import { UserRole } from "../../services/api";
import {
  getOpenSideMenuKeys,
  getUserMenusByRole,
  menuKeysPaths,
} from "./roles";
import { LoadingOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";

const SideMenu = (props: MenuProps) => {
  const { user } = useGlobal();
  const navigate = useNavigate();
  const [userMenus, setUserMenus] = React.useState<
    MenuProps["items"] | undefined
  >([]);

  React.useEffect(() => {
    if (user) {
      const menus = getUserMenusByRole(user.role as UserRole, navigate);
      setUserMenus(menus);
    }
  }, [user, navigate]);

  return (
    <Sider
      collapsible
      theme="light"
      style={{
        borderRight: "1px solid rgba(0,0,0,0.1)",
        background: "#f6f6f6",
        maxWidth: "20%",
      }}
    >
      {userMenus?.length ? (
        <Menu
          theme="light"
          defaultOpenKeys={Object.values(menuKeysPaths)}
          defaultSelectedKeys={[menuKeysPaths["/marketing"]]}
          selectedKeys={getOpenSideMenuKeys()}
          mode="inline"
          items={userMenus}
        />
      ) : (
        <center>
          <LoadingOutlined />
        </center>
      )}
    </Sider>
  );
};

export default SideMenu;
