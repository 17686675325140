import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Button, Table, TablePaginationConfig } from "antd";
import "./index.css";
import { useNavigate } from "react-router-dom";
import { IBanner, readableBannerTypes } from "../../../../services/structures";

const BannersTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IBanner[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        title: "ID",
        dataIndex: "id",
        key: "id",
      },
      {
        title: "Imagem",
        render: (_value: unknown, record: IBanner, _index: number) => (
          <img
            src={record.imageUrl || ""}
            alt="banner"
            style={{ maxWidth: 128 }}
          />
        ),
        dataIndex: "imageUrl",
        key: "imageUrl",
      },
      {
        title: "Tipo",
        dataIndex: "type",
        key: "type",
        render: (_value: unknown, record: IBanner, _index: number) => (
          <span>{readableBannerTypes[record.type] || record.type}</span>
        ),
      },
      {
        title: "Link",
        dataIndex: "destinationUrl",
        render: (_value: unknown, record: IBanner, _index: number) => (
          <a
            href={record.destinationUrl || ""}
            target={"_blank"}
            rel="noreferrer"
          >
            {record.destinationUrl || ""}
          </a>
        ),
        key: "destinationUrl",
      },
      {
        title: "Data de Início",
        dataIndex: "startingDate",
        key: "startingDate",
        render: (_value: unknown, record: IBanner, _index: number) => (
          <span>
            {record.startingDate
              ? dayjs(record.startingDate.split("T")[0]).format("DD/MM/YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Data de Fim",
        dataIndex: "finalDate",
        key: "finalDate",
        render: (_value: unknown, record: IBanner, _index: number) => (
          <span>
            {record.finalDate
              ? dayjs(record.finalDate.split("T")[0]).format("DD/MM/YYYY")
              : ""}
          </span>
        ),
      },
      {
        title: "Ações",
        render: (_value: unknown, record: IBanner, _index: number) => (
          <Button
            onClick={() => navigate(`/marketing/banners/${record.id}/edit`)}
          >
            Editar
          </Button>
        ),
        key: "edit",
      },
    ],
    [navigate]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ showSizeChanger: false, ...paginationConfig }}
      columns={columns}
      className="table-banners"
      loading={loading}
      rowKey="id"
    />
  );
};

export default BannersTable;
