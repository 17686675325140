import React from "react";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Popconfirm } from "antd";
import { useNavigate } from "react-router-dom";

export interface IBackButtonProps {
  pathToNavigate: string;
  title: string;
  showConfirmation?: boolean;
}

const BackButton = ({
  title,
  pathToNavigate,
  showConfirmation,
}: IBackButtonProps) => {
  const navigate = useNavigate();

  if (showConfirmation) {
    return (
      <Popconfirm
        placement="topLeft"
        title={"Deseja realmente voltar?"}
        description={"As alterações não salvas serão perdidas."}
        onConfirm={() => navigate(pathToNavigate)}
        okText="Sim, voltar"
        cancelText="Ficar na página"
      >
        <div style={{ display: "flex", flexWrap: "wrap", cursor: "pointer" }}>
          <ArrowLeftOutlined
            style={{
              cursor: "pointer",
              background: "white",
              borderRadius: 100,
              paddingLeft: 15,
              paddingRight: 15,
              marginRight: 10,
              minHeight: 32,
            }}
          />
          <p>{title}</p>
        </div>
      </Popconfirm>
    );
  }

  return (
    <div
      style={{
        display: "flex",
        flexWrap: "wrap",
        cursor: "pointer",
      }}
      onClick={() => navigate(pathToNavigate)}
    >
      <ArrowLeftOutlined
        style={{
          cursor: "pointer",
          background: "white",
          borderRadius: 100,
          paddingLeft: 15,
          paddingRight: 15,
          marginRight: 10,
          minHeight: 32,
        }}
      />
      <p>{title}</p>
    </div>
  );
};

export default BackButton;
