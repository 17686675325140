import { DeleteOutlined } from "@ant-design/icons";
import { Button, Input, notification, Select } from "antd";
import React from "react";
import {
  api,
  CampaignVariable,
  CampaignVariableInputType,
  CampaignVariableOperators,
  defaultAPIErrorHandler,
  IContractor,
} from "../../services/api";
import "./index.css";

function getVariableAllowedOperators(variable: CampaignVariable | undefined) {
  switch (variable) {
    case CampaignVariable.AGE:
      return ["<", ">", "=", ">=", "<=", "BETWEEN"];
    case CampaignVariable.NAME:
      return ["=", "CONTAINS"];
    case CampaignVariable.EMAIL:
    case CampaignVariable.PHONE:
    case CampaignVariable.CPF:
      return ["="];
    default:
      return [];
  }
}

function operatorTranslator(operator: string) {
  switch (operator) {
    case "<":
      return "menor que";
    case ">":
      return "maior que";
    case "=":
      return "igual a";
    case ">=":
      return "maior ou igual a";
    case "<=":
      return "menor ou igual a";
    case "BETWEEN":
      return "entre";
    case "CONTAINS":
      return "contém";
    default:
      return "";
  }
}

function variableTranslator(variable: string) {
  switch (variable) {
    case CampaignVariable.AGE:
      return "Idade";
    case CampaignVariable.NAME:
      return "Nome";
    case CampaignVariable.EMAIL:
      return "E-mail";
    case CampaignVariable.PHONE:
      return "Telefone";
    case CampaignVariable.CPF:
      return "CPF";
    default:
      return "";
  }
}

const formatAudiencesValue = (
  audience: CampaignVariableInputType,
  _contractors: IContractor[]
) => {
  if (audience.range?.length) {
    return audience.range?.[0] + " e " + audience.range?.[1];
  }

  return audience.value;
};

const CampaignAudiences = ({
  audiences,
  setAudiences,
  setUnsavedAudience,
  canEditFields = true,
}: {
  audiences: CampaignVariableInputType[];
  setAudiences: (audiences: CampaignVariableInputType[]) => void;
  setUnsavedAudience: (unsavedAudience: boolean) => void;
  canEditFields?: boolean;
}) => {
  const [editMode, setEditMode] = React.useState(false);
  const [selectedVariable, setSelectedVariable] =
    React.useState<CampaignVariable>();
  const [selectedOperator, setSelectedOperator] =
    React.useState<CampaignVariableOperators>();
  const [value, setValue] = React.useState<string | number | undefined>();
  const [range, setRange] = React.useState<
    (number | undefined)[] | undefined
  >();
  const [contractors, setContractors] = React.useState<IContractor[]>([]);

  const handleDeleteAudience = React.useCallback(
    (audienceId: number) => {
      setAudiences(audiences.filter((audience) => audience.id !== audienceId));
    },
    [audiences, setAudiences]
  );

  React.useEffect(() => {
    api
      .get("/contractors")
      .then((response) => {
        setContractors(response.data);
      })
      .catch(defaultAPIErrorHandler);
  }, []);

  React.useEffect(() => {
    setUnsavedAudience(editMode);
  }, [editMode, setUnsavedAudience]);

  const handleSaveAudience = React.useCallback(() => {
    const usingRange = selectedOperator === CampaignVariableOperators.BETWEEN;
    const invalidValue =
      !usingRange &&
      (value === undefined ||
        value === null ||
        (typeof value === "string" && !value.trim().length));

    const invalidRange =
      usingRange &&
      (isNaN(range?.[0] as number) ||
        isNaN(range?.[1] as number) ||
        range?.length !== 2);

    if (
      !selectedVariable ||
      !selectedOperator ||
      invalidValue ||
      invalidRange
    ) {
      notification.error({
        message:
          "Campos inválidos para adicionar uma audiência. Corrija-os e tente novamente.",
      });
      return;
    }

    const newAudience = {
      variable: selectedVariable as CampaignVariable,
      operator: selectedOperator as CampaignVariableOperators,
    } as CampaignVariableInputType;

    if (usingRange) {
      newAudience.range = range as number[];
    } else {
      newAudience.value = value;
    }

    newAudience.id = audiences.length;

    setAudiences([...audiences, newAudience]);
    setSelectedVariable(undefined);
    setSelectedOperator(undefined);
    setValue(undefined);
    setRange(undefined);
    setEditMode(false);
  }, [
    selectedVariable,
    selectedOperator,
    value,
    range,
    audiences,
    setAudiences,
  ]);

  const valueInputToRender = React.useMemo(() => {
    if (selectedOperator === CampaignVariableOperators.BETWEEN) {
      return (
        <>
          <Input
            value={range?.[0]}
            className="audience-value-input"
            onChange={(e) => setRange([+e.target.value, range?.[1]])}
          />{" "}
          e
          <Input
            value={range?.[1]}
            className="audience-value-input"
            onChange={(e) => setRange([range?.[0], +e.target.value])}
          />
        </>
      );
    }

    return (
      <Input
        className="audience-value-input"
        onChange={(e) => setValue(e.target.value)}
      />
    );
  }, [selectedOperator, range]);

  return (
    <div className="audiences-input">
      <span style={{ marginBottom: 5, marginRight: "auto" }}>Audiências</span>
      <div className="audiences-container">
        {audiences.length ? (
          <>
            <span style={{ background: "white" }}>
              Usuários que obedecem a <b>todas</b> as seguintes condições:
            </span>
            {audiences.map((audience) => (
              <div className="existing-audience-container" key={audience.id}>
                <div className="existing-audience-item-col">
                  {variableTranslator(audience.variable)}
                </div>
                <div className="existing-audience-item-col">
                  {operatorTranslator(audience.operator)}
                </div>
                <div className="existing-audience-item-col">
                  {formatAudiencesValue(audience, contractors)}
                </div>
                <Button
                  disabled={!canEditFields}
                  className="delete-audience-button"
                  onClick={() => handleDeleteAudience(audience.id as number)}
                >
                  <DeleteOutlined />
                </Button>
              </div>
            ))}
          </>
        ) : (
          <div style={{ margin: 10, fontWeight: "bold" }}>
            Todos os usuários
          </div>
        )}
        <div>
          {editMode && (
            <div className="new-audience-container">
              <div className="new-audience-field">
                <div>Selecione a variável</div>
                <Select
                  value={selectedVariable}
                  onChange={(value) => {
                    setSelectedVariable(value);
                    setSelectedOperator(undefined);
                  }}
                  className="audience-value-input"
                >
                  {Object.values(CampaignVariable).map((variable) => (
                    <Select.Option value={variable} key={variable}>
                      {variableTranslator(variable)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
              <div className="new-audience-field">
                <div>Selecione o operador</div>
                <Select
                  onChange={(value) => {
                    setSelectedOperator(value);
                    setValue(undefined);
                    setRange(undefined);
                  }}
                  value={selectedOperator}
                  className="audience-value-input"
                >
                  {getVariableAllowedOperators(selectedVariable).map(
                    (operator) => (
                      <Select.Option value={operator} key={operator}>
                        {operatorTranslator(operator)}
                      </Select.Option>
                    )
                  )}
                </Select>
              </div>
              <div className="new-audience-field">
                <>
                  <div>Selecione o valor</div>
                  {valueInputToRender}
                </>
              </div>
              <div
                style={{ display: "flex", alignSelf: "center", marginTop: 10 }}
              >
                <Button
                  style={{ marginRight: 10 }}
                  onClick={() => setEditMode(false)}
                >
                  Cancelar
                </Button>
                <Button onClick={handleSaveAudience}>Salvar</Button>
              </div>
            </div>
          )}
        </div>
        {!editMode && (
          <Button disabled={!canEditFields} onClick={() => setEditMode(true)}>
            Adicionar novo filtro
          </Button>
        )}
      </div>
    </div>
  );
};

export default CampaignAudiences;
