import React from "react";
import { Button, Table, TablePaginationConfig } from "antd";
import { ICompany } from "../../../../services/api";
import "./index.css";
import { useNavigate } from "react-router-dom";

const CompaniesTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: ICompany[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      {
        title: "Logo",
        render: (_value: unknown, record: ICompany, _index: number) => (
          <img src={record.logo || ""} alt="" style={{ maxWidth: 128 }} />
        ),
        dataIndex: "logo",
        key: "logo",
      },
      {
        title: "CNPJ",
        dataIndex: "cnpj",
        key: "cnpj",
      },
      {
        title: "Razão Social",
        dataIndex: "razao_social",
        key: "razao_social",
      },
      {
        title: "Nome Fantasia",
        dataIndex: "nome_fantasia",
        key: "nome_fantasia",
      },
      {
        title: "Ações",
        render: (_value: unknown, record: ICompany, _index: number) => (
          <Button
            onClick={() =>
              navigate(`/admin/companies/stores/${record.id}`)
            }
          >
            PDVs
          </Button>
        ),
        key: "edit",
      },
    ],
    [navigate]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ ...paginationConfig, showSizeChanger: false }}
      columns={columns}
      className="table-companies"
      loading={loading}
      rowKey="cnpj"
    />
  );
};

export default CompaniesTable;
