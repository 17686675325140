import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Card, Table, TablePaginationConfig, Button, notification } from "antd";
import {
  ReportStatus,
  ReportType,
  api,
  defaultAPIErrorHandler,
} from "../../../../services/api";
import "./index.css";
import { IReport } from "../../../../services/api";
import { useNavigate } from "react-router-dom";

const status = {
  DONE: "Concluido",
  PROCESSING: "Processando",
  ERROR: "Erro",
};

const reportType = {
  SYSDUCASH_COMPANIES: "Empresas",
  SYSDUCASH_STORES: "Pontos de venda",
  WALLETAPI_NEW_USERS: "Novos usuários",
  SYSDUCASH_VALIDATION: "Validação",
};

const ReportsCards = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IReport[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    (data: { report_type: string }) => {
      api
        .post("/reports", { type: data.report_type }, {})
        .then(() => {
          notification.info({
            message:
              "Gerando relatório... Você receberá uma notificação quando for concluído!",
          });

          setTimeout(() => navigate(0), 2000);
        })
        .catch(defaultAPIErrorHandler);
    },
    [navigate]
  );

  const newUsersReport = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleSubmit({ report_type: ReportType.WALLETAPI_NEW_USERS });
  };

  const validationReport = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleSubmit({ report_type: ReportType.SYSDUCASH_VALIDATION });
  };

  const companiesReports = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleSubmit({ report_type: ReportType.SYSDUCASH_COMPANIES });
  };

  const storesReports = (e: { preventDefault: () => void }) => {
    e.preventDefault();
    handleSubmit({ report_type: ReportType.SYSDUCASH_STORES });
  };

  const handleReportDownload = React.useCallback(async (report: IReport) => {
    api
      .get(`/reports/download/${report.id}`)
      .then((response) => {
        window.open(response.data?.signedUrl, "_blank");
      })
      .catch(defaultAPIErrorHandler);
  }, []);

  const columns = React.useMemo(
    () => [
      {
        title: "Tipo de relatório",
        dataIndex: "type",
        key: "type",
        render: (_value: unknown, record: IReport, _index: number) => (
          <span>{reportType[record.type]}</span>
        ),
      },
      {
        title: "Solicitante do relatório",
        dataIndex: ["user", "name"],
        key: "user.name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_value: unknown, record: IReport, _index: number) => (
          <span>{status[record.status]}</span>
        ),
      },
      {
        title: " Mensagem de status",
        dataIndex: "statusMessage",
        key: "statusMessage",
      },
      {
        title: "Data de geração",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_value: unknown, record: IReport, _index: number) => (
          <span>
            {record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: "Baixar relatório",
        dataIndex: "fileUrl",
        key: "fileUrl",
        render: (_value: unknown, record: IReport, _index: number) => (
          <div>
            {record.status === ReportStatus.DONE ? (
              <Button
                onClick={() => handleReportDownload(record)}
                className="report-button"
              >
                Baixar relatório
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
    [handleReportDownload]
  );

  return (
    <>
      <div className="cards-container">
        <Card title="Novos usuários" bordered={false} className="report-cards">
          <div style={{ display: "back" }}>
            <Button onClick={newUsersReport} className="report-button">
              Gerar relatório
            </Button>
          </div>
        </Card>

        <Card title="Validação" bordered={false} className="report-cards">
          <div style={{ display: "back" }}>
            <Button onClick={validationReport} className="report-button">
              Gerar relatório
            </Button>
          </div>
        </Card>

        <Card title="Empresas" bordered={false} className="report-cards">
          <div style={{ display: "back" }}>
            <Button onClick={companiesReports} className="report-button">
              Gerar relatório
            </Button>
          </div>
        </Card>

        <Card title="PDV" bordered={false} className="report-cards">
          <div style={{ display: "back" }}>
            <Button onClick={storesReports} className="report-button">
              Gerar relatório
            </Button>
          </div>
        </Card>
      </div>
      <Table
        dataSource={data}
        pagination={{ showSizeChanger: false, ...paginationConfig }}
        columns={columns}
        className="reports-table"
        loading={loading}
        rowKey="id"
      />
    </>
  );
};

export default ReportsCards;
