import React from "react";
import "dayjs/locale/pt-br";
import { LoadingOutlined } from "@ant-design/icons";
import { Form, Button, Input, notification, Checkbox, Select } from "antd";
import BackButton from "../../../../components/back-button";
import {
  api,
  defaultAPIErrorHandler,
  UserRole,
} from "../../../../services/api";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { IUser } from "../../../../hooks/global.structures";
import { roleReadableNames } from "../../../../components/sidemenu/roles";

const EditUser = () => {
  const [loading, setLoading] = React.useState(false);
  const location = useLocation();
  const user = location.state as IUser;

  const navigate = useNavigate();

  const handleSubmit = React.useCallback(
    async (data: {
      name: string;
      email: string;
      role: UserRole;
      access_allowed: boolean;
      password?: string;
    }) => {
      setLoading(true);

      const { name, email, role, password, access_allowed } = data;

      const body: {
        name?: string;
        email?: string;
        role?: UserRole;
        password?: string;
        deletedAt?: null;
      } = {
        ...(name !== user.name && { name: data.name }),
        ...(email !== user.email && { email: data.email }),
        ...(role !== user.role && { role: data.role }),
        ...(password && { password }),
        ...(!!user.deletedAt && access_allowed && { deletedAt: null }),
      };

      const shouldBeDeleted = !data.access_allowed && !user.deletedAt;
      const shouldBeUpdated = !!Object.keys(body).length;

      if (shouldBeDeleted) {
        try {
          await api.delete(`/users/${user.id}`);
          notification.success({
            message: "Usuário desativado com sucesso!",
          });
        } catch (error) {
          notification.error({
            message: "Ocorreu um erro ao desativar o usuário.",
          });
          setLoading(false);
          return;
        }
      }

      if (!shouldBeUpdated) {
        if (!shouldBeDeleted) {
          notification.info({
            message: "Nenhuma alteração foi feita.",
          });
        }

        setLoading(false);
        return navigate("/admin/users");
      }

      try {
        await api.patch(`/users/${user.id}`, body);

        notification.success({
          message: "Usuário editado com sucesso!",
        });

        return navigate("/admin/users");
      } catch (error) {
        defaultAPIErrorHandler(error);
      } finally {
        setLoading(false);
      }
    },
    [navigate, user]
  );

  if (!user) {
    notification.error({
      message: "Usuário não encontrado",
    });

    return <Navigate to="/admin/users" />;
  }

  return (
    <div>
      <BackButton
        title={`Administração > Usuários > ${user.id} > Editar`}
        pathToNavigate="/admin/users"
        showConfirmation
      />
      <h1>Editar Usuário</h1>
      <br />
      <Form
        name="form-user-edit"
        labelCol={{ span: 4 }}
        wrapperCol={{ span: 14 }}
        initialValues={{
          name: user.name,
          email: user.email,
          role: user.role,
          access_allowed: !user.deletedAt,
        }}
        layout="horizontal"
        onFinish={handleSubmit}
        style={{ maxWidth: 600, minWidth: 100 }}
      >
        <Form.Item
          label="Nome"
          name="name"
          rules={[
            {
              min: 3,
              required: true,
              message: "O nome é obrigatório e deve mais de 3 caracteres!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Email"
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "O email inserido não é válido!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Nova senha"
          name="password"
          rules={[
            {
              min: 8,
              message: "A senha inserida deve ter 8 ou mais caracteres!",
            },
          ]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Perfil"
          name="role"
          rules={[
            {
              required: true,
              message: "O tipo de perfil é obrigatório!",
            },
          ]}
        >
          <Select>
            {Object.keys(UserRole).map((role) => (
              <Select.Option key={role} value={role}>
                {roleReadableNames[role as UserRole]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="access_allowed"
          valuePropName="checked"
          wrapperCol={{ offset: 2, span: 16 }}
        >
          <Checkbox>Acesso permitido</Checkbox>
        </Form.Item>

        <Form.Item>
          <Button type="primary" htmlType="submit">
            {loading ? <LoadingOutlined /> : "Salvar"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default EditUser;
