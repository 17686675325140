import React from "react";
import { Badge, Button, Menu, Popover } from "antd";
import { LogoutOutlined, BellOutlined } from "@ant-design/icons";
import "./index.css";

import type { MenuProps } from "antd";
import { useGlobal } from "../../hooks/global";
import { useNavigate } from "react-router-dom";
import { roleReadableNames } from "../sidemenu/roles";
import { UserRole } from "../../services/api";
import { NotificationPanel } from "../notification-panel";

type MenuItem = Required<MenuProps>["items"][number];

const Header = () => {
  const { user, logout, notifications } = useGlobal();
  const navigate = useNavigate();
  const [notificationPopoverOpen, setNotificationPopoverOpen] =
    React.useState(false);

  const items: MenuItem[] = React.useMemo(
    () => [
      {
        key: "1",
        icon: <LogoutOutlined />,
        label: "Sair",
        onClick: () => {
          logout();
          navigate("/login");
        },
      },
    ],
    [logout, navigate]
  );

  return (
    <div className="user-header">
      <img
        className="logged-logo"
        src="/logo.png"
        alt="logo"
        width="100px"
        height="20px"
      />
      <div className="middle-info">
        <Popover
          placement="bottom"
          content={NotificationPanel({
            setOpen: setNotificationPopoverOpen,
          })}
          open={notificationPopoverOpen}
          onOpenChange={(open) => setNotificationPopoverOpen(open)}
          title="Notificações"
          trigger="click"
        >
          <Badge count={notifications.length} className="notification-bt">
            <Button>
              <BellOutlined />
            </Button>
          </Badge>
        </Popover>
        <div className="user-info">
          <div>{user?.name}</div>
          <div>Perfil: {roleReadableNames[(user?.role as UserRole) || ""]}</div>
        </div>
      </div>
      <Menu
        className="logout-menu"
        theme="light"
        mode="horizontal"
        items={items}
      />
    </div>
  );
};

export default Header;
