import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import { GlobalProvider } from "./hooks/global";
import AppRoutes from "./routes";
import { ConfigProvider, theme } from "antd";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <GlobalProvider>
    <ConfigProvider
      theme={{
        algorithm: theme.defaultAlgorithm,
      }}
    >
      <AppRoutes />
    </ConfigProvider>
  </GlobalProvider>
);
