import React from 'react';
import ValidationHeaderSearch from './headerSearch';
import CPFFound from './cpfFound';
import CPFNotFound from './cpfNotFound';
import {
  eligibilityApi,
  defaultAPIErrorHandler,
} from '../../../services/eligibility-api';
import { LoadingOutlined } from '@ant-design/icons';

const Validation = () => {
  const [loading, setLoading] = React.useState(false);
  const [showCPFFound, setShowCPFFound] = React.useState(false);
  const [showCPFNotFound, setShowCPFNotFound] = React.useState(false);

  const onSearch = (cpf: string) => {
    setLoading(true);
    eligibilityApi
      .get(`/whitelist/${cpf}`)
      .then((response) => {
        if (response.status === 200 && response.data.allowed) {
          setShowCPFFound(true);
          setShowCPFNotFound(false);
        }
        if (response.status === 200 && !response.data.allowed) {
          setShowCPFFound(false);
          setShowCPFNotFound(true);
        }
      })
      .catch(defaultAPIErrorHandler)
      .finally(() => setLoading(false));
  };

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <h1 style={{ marginRight: 10 }}>Validação</h1>
      </div>
      <ValidationHeaderSearch onSearch={onSearch} />
      <br></br>
      {loading ? (
        <center>
          <LoadingOutlined />
        </center>
      ) : (
        false
      )}
      {showCPFFound && <CPFFound />}
      {showCPFNotFound && <CPFNotFound />}
    </div>
  );
};

export default Validation;
