import React from "react";
import { Table, TablePaginationConfig } from "antd";
import { IStore } from "../../../../../services/api";
import "./index.css";

const StoresTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IStore[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const columns = React.useMemo(
    () => [
      {
        title: "Nome",
        dataIndex: "nome",
        key: "nome",
      },
      {
        title: "CEP",
        dataIndex: "cep",
        key: "cep",
      },
      {
        title: "Bairro",
        dataIndex: "bairro",
        key: "bairro",
      },
      {
        title: "Complemento",
        dataIndex: "complemento",
        key: "complemento",
      },
      {
        title: "Logradouro",
        dataIndex: "logradouro",
        key: "logradouro",
      },
      {
        title: "Número",
        dataIndex: "numero",
        key: "numero",
      },
      {
        title: "Cidade",
        dataIndex: "cidade",
        key: "cidade",
        render: (city: any) => <>{city.nome}</>,
      },
      {
        title: "Estado",
        dataIndex: "uf",
        key: "uf",
        render: (state: any) => <>{state.sigla}</>,
      },
    ],
    []
  );

  return (
    <Table
      dataSource={data}
      pagination={{ ...paginationConfig, showSizeChanger: false }}
      columns={columns}
      className="table-stores"
      loading={loading}
      rowKey="id"
    />
  );
};

export default StoresTable;
