import { Form, Input, Select, DatePicker, Button } from "antd";
import "dayjs/locale/pt-br";
import locale from "antd/es/date-picker/locale/pt_BR";
import { Action, Entity, IFindManyActionsHistoryDTO } from "../../services/api";
import "./index.css";
import { Dayjs } from "dayjs";

const ActionsHistoryFilters = ({
  onSubmit,
}: {
  onSubmit: (
    filters: IFindManyActionsHistoryDTO & {
      startDate?: Dayjs;
      endDate?: Dayjs;
    }
  ) => void;
}) => {
  return (
    <Form layout="vertical" onFinish={onSubmit}>
      <div className="acf-container">
        <Form.Item
          name="userName"
          label="Nome do usuário"
          className="acf-form-item"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="userId"
          label="ID do usuário"
          className="acf-form-item"
        >
          <Input />
        </Form.Item>

        <Form.Item name="action" label="Ação" className="acf-form-item">
          <Select>
            <Select.Option value="">Todas</Select.Option>
            {Object.keys(Action).map((key) => (
              <Select.Option value={key}>{key}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="entity"
          label="Entidade afetada"
          className="acf-form-item"
        >
          <Select>
            <Select.Option value="">Todas</Select.Option>
            {Object.keys(Entity).map((key) => (
              <Select.Option value={key}>{key}</Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item
          name="entityId"
          label="ID da entidade afetada"
          className="acf-form-item"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="changes"
          label="Modificações"
          className="acf-form-item"
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="startDate"
          label="Data inicial"
          className="acf-form-item"
        >
          <DatePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            locale={locale}
          />
        </Form.Item>

        <Form.Item name="endDate" label="Data final" className="acf-form-item">
          <DatePicker
            style={{ width: "100%" }}
            format={"DD/MM/YYYY"}
            locale={locale}
          />
        </Form.Item>

        <div className="acf-submit-container">
          <Button type="primary" htmlType="submit">
            Aplicar filtros
          </Button>
        </div>
      </div>
    </Form>
  );
};

export default ActionsHistoryFilters;
