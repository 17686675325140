import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import {
  Button,
  Collapse,
  Popconfirm,
  Table,
  TablePaginationConfig,
  notification,
} from "antd";
import "./index.css";
import {
  IApiAccess,
  api,
  defaultAPIErrorHandler,
} from "../../../../services/api";
import { useNavigate } from "react-router-dom";

const ApiAccessTable = ({
  data,
  loading,
  paginationConfig,
}: {
  data: IApiAccess[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
}) => {
  const navigate = useNavigate();

  const handleDelete = React.useCallback(
    async (id: string) => {
      try {
        await api.delete("/api-access/" + id);
        notification.success({
          message: "Acesso revogado com sucesso!",
        });
        navigate(0);
      } catch (err) {
        defaultAPIErrorHandler(err);
      }
    },
    [navigate]
  );

  const columns = React.useMemo(
    () => [
      {
        title: "Nome do parceiro",
        dataIndex: "partnerName",
        key: "partnerName",
      },
      {
        title: "Status de acesso",
        dataIndex: "active",
        key: "active",
        render: (_value: unknown, record: IApiAccess, _index: number) => (
          <span style={record.active ? { color: "green" } : { color: "red" }}>
            {record.active ? "Ativo" : "Revogado"}
          </span>
        ),
      },
      {
        title: "Token de acesso",
        dataIndex: "token",
        key: "token",
        render: (_value: unknown, record: IApiAccess, _index: number) => (
          <span>
            <Collapse>
              <Collapse.Panel header="Visualizar" key="1">
                <p>{record.token}</p>
              </Collapse.Panel>
            </Collapse>
          </span>
        ),
      },
      {
        title: "Data de criação",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_value: unknown, record: IApiAccess, _index: number) => (
          <span>
            {record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: "Data de modificação",
        dataIndex: "updatedAt",
        key: "updatedAt",
        render: (_value: unknown, record: IApiAccess, _index: number) => (
          <span>
            {record.updatedAt
              ? dayjs(record.updatedAt).format("DD/MM/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: "Ações",
        render: (_value: unknown, record: IApiAccess, _index: number) => (
          <>
            {record.active && (
              <Popconfirm
                placement="topLeft"
                title={"Confirmação"}
                description={"Deseja mesmo revogar este acesso?"}
                onConfirm={() => handleDelete(record.id)}
                okText="Sim"
                cancelText="Não"
              >
                <Button>Revogar acesso</Button>
              </Popconfirm>
            )}
          </>
        ),
        key: "edit",
      },
    ],
    [handleDelete]
  );

  return (
    <Table
      dataSource={data}
      pagination={{ showSizeChanger: false, ...paginationConfig }}
      columns={columns}
      className="table-api-access"
      loading={loading}
      rowKey="id"
    />
  );
};

export default ApiAccessTable;
