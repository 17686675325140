import React from "react";
import "dayjs/locale/pt-br";
import dayjs from "dayjs";
import { Table, TablePaginationConfig, Button } from "antd";
import { IAppUsers, IContractor, InsertStatus } from "../../../../services/api";
import "./index.css";

const status = {
  DONE: "Concluido",
  PROCESSING: "Processando",
  ERROR: "Erro",
};

const AppUsersTable = ({
  data,
  loading,
  paginationConfig,
  corporates,
}: {
  data: IAppUsers[];
  paginationConfig?: TablePaginationConfig;
  loading?: boolean;
  corporates: IContractor[];
}) => {
  const columns = React.useMemo(
    () => [
      {
        title: "Contratante",
        dataIndex: "corporate",
        key: "corporate",
        render: (_value: unknown, record: IAppUsers, _index: number) => (
          <span>{ corporates.find(corporate => corporate.id === record.corporate.toString())?.name }</span>
        ),
      },
      {
        title: "Realizou do insert",
        dataIndex: ["user", "name"],
        key: "user.name",
      },
      {
        title: "Status",
        dataIndex: "status",
        key: "status",
        render: (_value: unknown, record: IAppUsers, _index: number) => (
          <span>{status[record.status]}</span>
        ),
      },
      {
        title: " Mensagem de status",
        dataIndex: "statusMessage",
        key: "statusMessage",
      },
      {
        title: "Data de envio",
        dataIndex: "createdAt",
        key: "createdAt",
        render: (_value: unknown, record: IAppUsers, _index: number) => (
          <span>
            {record.createdAt
              ? dayjs(record.createdAt).format("DD/MM/YYYY HH:mm:ss")
              : ""}
          </span>
        ),
      },
      {
        title: "Arquivo de erros",
        dataIndex: "errorsFileUrl",
        key: "errorsFileUrl",
        render: (_value: unknown, record: IAppUsers, _index: number) => (
          <div>
            {record.errorsFileUrl !== null ? (
              <Button
                onClick={() => window.open(record.errorsFileUrl, "_blank")}
                className="app-users-button"
              >
                Arquivo de erros
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
      {
        title: "Arquivo enviado",
        dataIndex: "fileUrl",
        key: "fileUrl",
        render: (_value: unknown, record: IAppUsers, _index: number) => (
          <div>
            {record.status === InsertStatus.DONE ? (
              <Button
                onClick={() => window.open(record.fileUrl, "_blank")}
                className="app-users-button"
              >
                Arquivo enviado
              </Button>
            ) : (
              ""
            )}
          </div>
        ),
      },
    ],
    [corporates]
  );

  return (
    <>
      <Table
        dataSource={data}
        pagination={{ showSizeChanger: false, ...paginationConfig }}
        columns={columns}
        className="app-users-table"
        loading={loading}
        rowKey="id"
      />
    </>
  );
};

export default AppUsersTable;
