import { DownloadOutlined, UploadOutlined } from "@ant-design/icons";
import { Button, TablePaginationConfig, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "antd/es/upload";
import React from "react";
import {
    api,
    defaultAPIErrorHandler,
    ICompany,
    IPaginatedList,
} from "../../../services/api";
import CompaniesHeaderSearch from "./headerSearch";
import CompaniesTable from "./table";
import CompaniesError from "./error";
import SegmentsList from "./segments";
// @ts-ignore
import PlanilhaModeloCompany from '../../../downloads/PlanilhaModeloEmpresa.csv';

const Companies = () => {
    const [filters, setFilters] = React.useState({
        page: 0,
    });
    const [items, setItems] = React.useState<ICompany[]>([]);
    const [paginationConfig, setPaginationConfig] =
        React.useState<TablePaginationConfig>({});
    const [loading, setLoading] = React.useState(false);
    const [errorItems, setErrorItems] = React.useState<ICompany[] | undefined>();
    const [file, setFile] =
        React.useState<UploadFile | null>(null);
    const [showSegments, setShowSegments] = React.useState(false);

    const onChangePagination = React.useCallback(
        (page: number, _pageSize?: number) => {
            setFilters({
                ...filters,
                page: page - 1,
            });
        },
        [filters]
    );

    const onSearch = React.useCallback(
        (searchFilters: {
            cnpj?: string,
            razao_social?: string,
            nome_fantasia?: string
        }) => {

            setFilters({
                ...filters,
                ...searchFilters
            });
        },
        [filters]
    );

    const handleFileUpload = React.useCallback(
        (info: UploadChangeParam<UploadFile>) => {
            if (info.file.type !== 'text/csv' || info.fileList.length === 0) {
                setFile(null);
                return;
            }

            setFile(info.file);
        },
        []
    )

    const handleApiUpload = () => {
        var bodyFormData = new FormData();

        bodyFormData.append('file', file?.originFileObj as File);

        setLoading(true);
        api.post<IPaginatedList<ICompany>>(`/companies`, bodyFormData, {
            headers: { "Content-Type": "multipart/form-data" },
        })
            .then((response) => {
                setPaginationConfig({
                    current: response.data.page + 1,
                    total: response.data.totalItems,
                    pageSize: response.data.itemsPerPage,
                    onChange: onChangePagination,
                });
                setItems(response.data.items);
                setErrorItems(response.data.errors)
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => {
                setFile(null)
                setLoading(false);
            });
    }

    React.useEffect(() => {
        setLoading(true);
        setItems([]);
        api
            .get<IPaginatedList<ICompany>>("/companies", {
                params: filters,
            })
            .then((response) => {
                setPaginationConfig({
                    current: response.data.page + 1,
                    total: response.data.totalItems,
                    pageSize: response.data.itemsPerPage,
                    onChange: onChangePagination,
                });
                setItems(response.data.items);
            })
            .catch(defaultAPIErrorHandler)
            .finally(() => setLoading(false));
    }, [filters, onChangePagination]);

    return (
        <div>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h1 style={{ marginRight: 10 }}>Empresas Parceiras</h1>
            </div>
            <CompaniesHeaderSearch onSearch={onSearch} />

            <a href={PlanilhaModeloCompany} download={true}>
                <Button style={{ margin: 16 }} icon={<DownloadOutlined />}>Baixar CSV modelo</Button>
            </a>

            <Button
                type={showSegments ? 'primary' : 'default'}
                onClick={() => setShowSegments(!showSegments)}
                style={{ margin: 16 }}
            >
                {'Segmentos'}
            </Button>

            < Upload
                customRequest={({ onSuccess }) => {
                    setTimeout(() => {
                        onSuccess?.("ok");
                    }, 0);
                }}
                fileList={file ? [file] : []}
                multiple={false}
                onRemove={() => setFile(null)}
                onChange={handleFileUpload}
            >
                <Button style={{ margin: 16 }} icon={<UploadOutlined />}>Importar Arquivo de Empresa(s)</Button>
            </Upload>

            <Button
                type="primary"
                onClick={handleApiUpload}
                disabled={file === null || loading}
                style={{ margin: 16 }}
            >
                {loading ? 'Enviando' : 'Enviar'}
            </Button>

            {showSegments && <SegmentsList />}

            {errorItems && errorItems.length > 0 && <CompaniesError data={errorItems} />}

            <CompaniesTable
                paginationConfig={paginationConfig}
                data={items}
                loading={loading}
            />
        </div>
    );
};

export default Companies;
